import type { FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { AsideMenu } from './AsideMenu'
import { Container } from './Container'
import { ItemListProductNews } from './ItemListProductNews'
import styles from './ProductNewsPage.module.sass'

export type ProductNewsPageProps = NonNullable<PageProps['page']['productNewsPage']>

export const ProductNewsPage: FunctionComponent<ProductNewsPageProps> = ({
	heading,
	menuCategories,
	productNewsArticles,
}) => {
	return (
		<Container size="normal">
			<div className={styles.wrapper}>
				<AsideMenu heading={heading} categories={menuCategories} />
				<Container size="small" disableGutters>
					<ItemListProductNews listCategories={menuCategories} productNewsArticles={productNewsArticles} />
				</Container>
			</div>
		</Container>
	)
}
