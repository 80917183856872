import type { FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { Container } from './Container'
import { TileGrid } from './TileGrid'

export type ArticlesPageProps = NonNullable<PageProps['page']['articlesPage']>

export const ArticlesPage: FunctionComponent<ArticlesPageProps> = ({ magazineArticlePagination }) => {
	return (
		<Container size="normal">
			<TileGrid articlePagination={magazineArticlePagination} />
		</Container>
	)
}
