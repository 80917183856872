import 'hiding-header/dist/style.css'
import Image from 'next/image'
import { type FormEventHandler, type FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useMirrorLoading } from 'shared-loading-indicator'
import type { MailingListResult } from '../data/MailingListFragment'
import { useGlobalContext } from '../pages/[[...path]]'
import { api } from '../utilities/api'
import { useIsInView } from '../utilities/useIsInView'
import { BreakableContent } from './BreakableContent'
import { Button } from './Button'
import { Container } from './Container'
import { Input } from './Input'
import styles from './MailingListBannerSecondary.module.sass'
import { SocialLinks } from './SocialLinks'
import { useSite } from './contexts/SiteContextProvider'
import { useTranslate } from './contexts/TranslationsContextProvider'

type MailingListBannerSecondaryProps = {
	list: MailingListResult
}
export const MailingListBannerSecondary: FunctionComponent<MailingListBannerSecondaryProps> = ({ list }) => {
	const translation = useTranslate()
	const { socialLinks } = useGlobalContext()

	const form = useRef<HTMLFormElement>(null)
	const [email, setEmail] = useState('')
	const [BrevoData] = useState(list.brevoData)
	const [mailingListId] = useState(list.id)

	const { siteCode } = useSite()

	const mutation = api.subscribeOnMailingList.send.useMutation({
		onError: () => {
			toast('Něco se při odesílání pokazilo. Zkuste to znovu prosím později.', {
				type: 'error',
			})
		},
	})
	const isPending = mutation.isPending
	useMirrorLoading(isPending)

	const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
		(event) => {
			event.preventDefault()

			if (BrevoData) {
				mutation.mutate({
					siteCode,
					mailingListId: mailingListId,
					brevoListId: BrevoData.listId,
					email,
				})
			} else {
				console.error('Brevo data not found.')
				toast('Něco se při odesílání pokazilo. Zkuste to znovu prosím později.', {
					type: 'error',
				})
			}
		},
		[BrevoData, mutation, siteCode, mailingListId, email],
	)

	const sendDataLayers = useCallback(
		(isSuccess: boolean) => {
			window.dataLayer = window.dataLayer || []

			if (isSuccess) {
				window.dataLayer.push({
					event: 'form_newsletter_subscribe',
					hit: {
						event_type: 'generic_event_content',
						content_type: 'form',
						content_id:
							mutation.data?.contemberStatus && 'id' in mutation.data.contemberStatus
								? mutation.data?.contemberStatus.id
								: '',
						user_email: email,
					},
					_clear: true,
				})
			} else {
				window.dataLayer.push({
					event: 'form_newsletter_error',
					hit: {
						event_type: 'generic_event_content',
						content_type: 'form',
					},
					_clear: true,
				})
			}
		},
		[email, mutation.data?.contemberStatus],
	)

	// success/error form handling
	useEffect(() => {
		if (mutation.status === 'error' || mutation.status === 'success') {
			mutation.data?.status === 'success'
				? sendDataLayers(true)
				: mutation.data?.status === 'error'
				? sendDataLayers(false)
				: null
		}
	}, [mutation.data?.status, mutation.status, sendDataLayers])

	useEffect(() => {
		if (mutation.data?.status) {
			if (mutation.data.status === 'success') {
				form.current?.reset()
			} else {
				console.log('error: ', mutation.data)
			}
		}
	}, [mutation.data])

	const isInView = useIsInView(
		form,
		{
			threshold: 1,
		},
		true,
	)

	useEffect(() => {
		if (isInView) {
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				event: 'form_newsletter_view',
				hit: {
					event_type: 'generic_event_content',
					content_type: 'form',
				},
				_clear: true,
			})
		}
	}, [isInView])

	return (
		<div className={styles.wrapper}>
			<Container disableGutters size="normal">
				<div className={styles.main}>
					<div className={styles.column}>
						{list?.title && (
							<h3 className={styles.title}>
								<BreakableContent text={list.title} breakableFrom="480" />
							</h3>
						)}
						{list?.description && (
							<div className={styles.description}>
								<BreakableContent isParagraph text={list.description} breakableFrom="480" />
							</div>
						)}
						<form className={styles.form} onSubmit={onSubmit} ref={form}>
							<div className={styles.inputWrapper}>
								<Input
									name="email"
									type="email"
									required
									placeholder={translation('mailingListBanner.emailPlaceholder')}
									onChange={(e) => {
										setEmail(e.target.value)
									}}
									value={email}
									backgroundColor="white"
								/>
								{mutation.data?.status != undefined && (
									<div className={styles.inputSubscribed}>
										{mutation.data.status === 'success'
											? translation('newsletter.subscription.success')
											: mutation.data.errorResponse && mutation.data.errorResponse.code === 'duplicate_parameter'
											? translation('newsletter.subscription.duplicate_parameter')
											: translation('newsletter.subscription.error')}
									</div>
								)}
							</div>
							<div className={styles.buttonWrapper}>
								<Button size="small" type="submit" disabled={mutation.isPending}>
									{translation('mailingListBanner.button.label')}
								</Button>
							</div>
						</form>
					</div>
					<div className={styles.column}>
						{list?.image && (
							<div className={styles.imageWrapper}>
								<Image
									className={styles.image}
									src={list.image.url}
									width={list.image.width}
									height={list.image.height}
									alt={list.image.alt ?? ''}
								/>
							</div>
						)}
						{socialLinks && <SocialLinks items={socialLinks.items} isCircleBackground />}
					</div>
				</div>
			</Container>
		</div>
	)
}
