import { useState, type FunctionComponent, useCallback, type FormEventHandler } from 'react'
import styles from './ContentLock.module.sass'
import type { FormResult } from '../data/FormFragment'
import { Button } from './Button'
import { Icon } from './Icon'
import { Modal } from './Modal'
import { Input } from './Input'
import { api } from '../utilities/api'
import { useSite } from './contexts/SiteContextProvider'
import { toast } from 'react-toastify'
import { useMirrorLoading } from 'shared-loading-indicator'

type ContentLock = {
	form: FormResult | undefined
	title: string | undefined
	buttonlabel: string | undefined
	deactivateLock?: () => void
}

export const ContentLock: FunctionComponent<ContentLock> = ({ form, title, buttonlabel, deactivateLock }) => {
	const [modalOpen, setModalOpen] = useState(false)

	const [email, setEmail] = useState<string | null>(null)
	const [phone, setPhone] = useState<string | null>(null)
	const [name, setName] = useState<string | null>(null)
	const [jobTitle, setJobTitle] = useState<string | null>(null)

	const close = () => {
		setModalOpen(false)
		clearForm()
	}
	const clearForm = useCallback(() => {
		setEmail('')
		setJobTitle('')
		setName('')
		setPhone('')
	}, [])

	const { siteCode } = useSite()

	const mutation = api.contact.send.useMutation({
		onSuccess: () => {
			close()
			setJobTitle('')
			setName('')
			setPhone('')
			setEmail('')
			if (deactivateLock) {
				deactivateLock()
			}
		},
		onError: () => {
			toast('Něco se při odesílání pokazilo. Zkuste to znovu prosím později.', {
				type: 'error',
			})
		},
	})
	const isPending = mutation.isPending
	useMirrorLoading(isPending)

	const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
		(event) => {
			event.preventDefault()

			mutation.mutate({
				siteCode,
				email: email ?? '',
				phone: phone ?? '',
				name: name ?? '',
				jobTitle: jobTitle ?? '',
			})
			clearForm()
		},
		[mutation, siteCode, email, phone, name, jobTitle, clearForm],
	)

	return (
		<div className={styles.wrapper}>
			<div className={styles.gradient} />
			<div className={styles.content}>
				<div className={styles.lock}>
					<Icon name="lock" />
				</div>
				<div className={styles.title}>{title}</div>
				<Button type="button" onClick={() => setModalOpen(true)} size="small">
					{buttonlabel}
				</Button>
			</div>
			<Modal close={() => setModalOpen(false)} isOpen={modalOpen}>
				<form className={styles.form} action="" method="POST" onSubmit={onSubmit}>
					<fieldset className={styles.formIn}>
						<div className={styles.formTitle}>{form?.title}</div>
						{form?.fields.map((field) => (
							<Input
								key={field.label}
								type={field.type === 'email' ? 'email' : 'text'}
								onChange={(e) =>
									field.type === 'email'
										? setEmail(e.target.value)
										: field.type === 'phone'
										? setPhone(e.target.value)
										: field.type === 'name'
										? setName(e.target.value)
										: setJobTitle(e.target.value)
								}
								placeholder={field.label}
								formLocated
							/>
						))}
						<div className={styles.buttonWrapper}>
							<Button type="submit" size="small">
								{form?.submit}
							</Button>
						</div>
						<div className={styles.buttonWrapper}>
							<Button type="button" onClick={close} variant="text" size="small">
								{form?.close}
							</Button>
						</div>
					</fieldset>
				</form>
			</Modal>
		</div>
	)
}
