export const fileTypeShortcut = {
	docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	doc: 'application/msword',
	pdf: 'application/pdf',
	xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	html: 'text/html',
	jpeg: 'image/jpeg',
} as const

export const getFileType = (fileType: string) => {
	const type =
		fileType === fileTypeShortcut.doc
			? 'doc'
			: fileType === fileTypeShortcut.docx
			? 'docx'
			: fileType === fileTypeShortcut.pdf
			? 'pdf'
			: fileType === fileTypeShortcut.xlsx
			? 'xlsx'
			: fileType === fileTypeShortcut.html
			? 'html'
			: fileType === fileTypeShortcut.jpeg
			? 'jpeg'
			: ''
	return type
}
