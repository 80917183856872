import clsx from 'clsx'
import Link from 'next/link'
import { useMemo, useState, type FunctionComponent } from 'react'
import { contemberLinkToHref, contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import type { HeaderProps } from './Header'
import styles from './HeaderMobileMenu.module.sass'
import { Icon } from './Icon'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type HeaderMobileMenuProps = Pick<HeaderProps, 'links'> & {
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	contactPageLink: string | undefined
}

export const HeaderMobileMenu: FunctionComponent<HeaderMobileMenuProps> = ({
	links,
	isOpen,
	setIsOpen,
	contactPageLink,
}) => {
	const [activeLinkWithSubmenu, setActiveLinkWithSubmenu] = useState<string | null>(null)

	const activeLink = useMemo(() => {
		return links?.find((link) => link?.id === activeLinkWithSubmenu) ?? null
	}, [activeLinkWithSubmenu, links])

	const translation = useTranslate()

	return (
		<div className={clsx(styles.wrapper, isOpen && styles.is_open)}>
			<div className={styles.header}>
				<button
					type="button"
					className={clsx(styles.back, activeLink && styles.is_visible)}
					onClick={() => setActiveLinkWithSubmenu(null)}
				>
					<Icon name="chevron" />
				</button>
				<div className={styles.title}>{activeLink?.title}</div>
				<button type="button" className={styles.cross} onClick={() => setIsOpen(false)}>
					<Icon name="cross" />
				</button>
			</div>
			<div className={styles.linksWrapper}>
				<ul className={clsx(styles.links, activeLink && styles.is_hidden)}>
					{links?.map(
						(link) =>
							link && (
								<li key={link.id} className={styles.item}>
									<Link
										className={styles.link}
										{...contemberLinkToHrefTargetRel(link)}
										onClick={(event) => {
											if (link.nestedLinks) {
												event.preventDefault()
												setActiveLinkWithSubmenu(link.id)
												return
											}
											setIsOpen(false)
										}}
									>
										{link.title ?? contemberLinkToHref(link)}
										{link.nestedLinks && (
											<div className={styles.chevron}>
												<Icon name="chevron" />
											</div>
										)}
									</Link>
								</li>
							),
					)}
					{contactPageLink && (
						<li className={styles.item}>
							<Link className={clsx(styles.link, styles.is_highlighted)} href={contactPageLink}>
								{translation('header.contactLabel')}
							</Link>
						</li>
					)}
				</ul>

				<ul className={clsx(styles.links, styles.view_nested, !activeLink && styles.is_hidden)}>
					{activeLink?.nestedLinks?.items?.map((item) => {
						const link = item?.link
						if (!link) {
							return null
						}
						return (
							<li key={link.id} className={styles.item}>
								<Link
									className={styles.link}
									{...contemberLinkToHrefTargetRel(link)}
									onClick={() => {
										setIsOpen(false)
										setActiveLinkWithSubmenu(null)
									}}
								>
									{link.title ?? contemberLinkToHref(link)}
								</Link>
							</li>
						)
					})}
				</ul>
			</div>
		</div>
	)
}
