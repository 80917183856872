import type { FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { AsideMenu } from './AsideMenu'
import { Container } from './Container'
import { ItemListTv } from './ItemListTv'
import styles from './TvPage.module.sass'

export type TvPageProps = NonNullable<PageProps['page']['tvPage']>

export const TvPage: FunctionComponent<TvPageProps> = ({ heading, menuCategories, tvArticlePagination }) => {
	return (
		<Container size="normal">
			<div className={styles.wrapper}>
				<AsideMenu heading={heading} categories={menuCategories} />
				<Container size="small" disableGutters>
					<ItemListTv tvArticlePagination={tvArticlePagination} />
				</Container>
			</div>
		</Container>
	)
}
