import clsx from 'clsx'
import 'hiding-header/dist/style.css'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { contemberLinkToHref, contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Container } from './Container'
import styles from './MagazineHeader.module.sass'

export type MagazineHeaderProps = NonNullable<PageProps['magazineHeader']>

export const MagazineHeader: FunctionComponent<MagazineHeaderProps> = ({ links }) => {
	return (
		<div className={styles.header}>
			<Container size="normal">
				<div className={styles.headWrapper}>
					<div className={styles.head}>
						<header className={styles.wrapper}>
							<div className={styles.in}>
								<ul className={styles.links}>
									{links?.map(
										(link) =>
											link && (
												<li className={styles.item} key={link.id}>
													<Link
														className={clsx(styles.link, link.isActive && styles.linkActive)}
														{...contemberLinkToHrefTargetRel(link)}
													>
														{link.title ?? contemberLinkToHref(link)}
													</Link>
												</li>
											),
									)}
								</ul>
							</div>
						</header>
					</div>
				</div>
			</Container>
		</div>
	)
}
