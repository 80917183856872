import 'hiding-header/dist/style.css'
import type { FunctionComponent } from 'react'
import styles from './FrontHeader.module.sass'
import { Icon, type IconName } from './Icon'

type FrontHeaderProps = {
	iconName?: IconName
	title: string
}
export const FrontHeader: FunctionComponent<FrontHeaderProps> = ({ iconName, title }) => {
	return (
		<div className={styles.header}>
			{iconName && (
				<div className={styles.iconWrapper}>
					<Icon name={iconName} />
				</div>
			)}
			<h2 className={styles.title}>{title}</h2>
		</div>
	)
}
