import Link from 'next/link'
import type { FunctionComponent, ReactNode } from 'react'
import type { InternalLinkResult } from '../data/InternalLinkFragment'
import type { LinkResult } from '../data/LinkFragment'
import { contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'

export type LinkChildrenProps = (LinkDefaultProps | LinkInternalProps) & {
	children: ReactNode
	className?: string
}

type LinkDefaultProps = {
	type: 'default'
	link?: LinkResult | null
}

type LinkInternalProps = {
	type: 'internal'
	link?: InternalLinkResult | null
}

export const LinkChildren: FunctionComponent<LinkChildrenProps> = ({ type, link, children, className }) => {
	switch (type) {
		case 'default':
			return link ? (
				<Link className={className} {...contemberLinkToHrefTargetRel(link)}>
					{children}
				</Link>
			) : (
				<div className={className}>{children}</div>
			)
		case 'internal':
			return link ? (
				<Link className={className} href={link.url}>
					{children}
				</Link>
			) : (
				<div className={className}>{children}</div>
			)
	}
}
