import clsx from 'clsx'
import 'hiding-header/dist/style.css'
import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import type { EventResult } from '../data/EventFragment'
import { getDayFromDate } from '../utilities/getYearAndMonthFromDate'
import styles from './EventBox.module.sass'

type EventBoxProps = {
	event: EventResult & {
		formattedLongMonth?: string
	}
}
export const EventBox: FunctionComponent<EventBoxProps> = ({ event }) => {
	const content = event.photo ? (
		<div className={styles.imageWrapper}>
			<Image
				className={styles.image}
				src={event.photo.url}
				alt={event.photo.alt ?? ''}
				fill
				sizes="(min-width: 48rem) 10vw, 50vw)"
			/>
		</div>
	) : (
		<div className={styles.event}>
			<div className={clsx(event.url && styles.eventIconLink, styles.eventIcon)}>
				{event.fromDate && (
					<>
						<div className={styles.eventDay}>{getDayFromDate(event.fromDate)}</div>
						<div
							style={{
								'--bg-color': `${event.calendarColor}`,
							}}
							className={styles.eventMonth}
						>
							{event.formattedLongMonth}
						</div>
					</>
				)}
			</div>
			<div className={styles.eventContent}>
				{event.title && <div className={styles.eventTitle}>{event.title}</div>}
				{event.location && <div className={styles.eventLocation}>{event.location}</div>}
			</div>
		</div>
	)
	return (
		<div className={styles.wrapper}>
			{event.url ? (
				<Link href={event.url} className={styles.eventLink} target="_blank">
					{content}
				</Link>
			) : (
				content
			)}
		</div>
	)
}
