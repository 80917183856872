import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { WebinarDateResult } from '../data/WebinarDateFragment'
import { formatDate } from '../utilities/formatDate'
import { roundTheCurrentTimeToMinutes } from '../utilities/roundTheCurrentTimeToMinutes'
import { Button } from './Button'
import { LinkChildren } from './LinkChildren'
import styles from './WebinarDateBanner.module.sass'

export type WebinarDateBannerProps = {
	webinarDate: WebinarDateResult
	isFiltered?: boolean
	buttonVisible?: boolean
}

export const WebinarDateBanner: FunctionComponent<WebinarDateBannerProps> = ({
	webinarDate,
	isFiltered = false,
	buttonVisible = false,
}) => {
	const isPastWebinar = webinarDate.date && webinarDate.date < roundTheCurrentTimeToMinutes()

	return (
		<div
			className={clsx(
				styles.wrapper,
				isFiltered && styles.wrapperFiltered,
				!webinarDate.webinar?.tileImage && styles.background,
			)}
		>
			<LinkChildren type="internal" link={webinarDate.webinar?.link} className={styles.link}>
				{webinarDate.webinar?.tileImage && (
					<div className={styles.imageWrapper}>
						<Image
							className={styles.image}
							src={webinarDate.webinar.tileImage.url}
							fill
							style={{ objectFit: 'cover' }}
							sizes="(min-width: 48rem) 30vw, (min-width: 60rem) 50vw, 100vw)"
							alt={webinarDate.webinar.tileImage.alt ?? ''}
						/>
					</div>
				)}
				<div className={styles.content}>
					<div className={clsx(styles.contentTop, isPastWebinar && styles.contentTopFullTitle)}>
						{webinarDate.webinar?.title && <h3 className={styles.contentTitle}>{webinarDate.webinar.title}</h3>}
					</div>
					{webinarDate.date && <div className={styles.date}>{formatDate('cs', webinarDate.date, true)}</div>}
					{webinarDate.webinar?.labels && webinarDate.webinar.labels.length > 0 && (
						<div className={styles.labelGroup}>
							{webinarDate.webinar.labels.map((label) => (
								<span className={styles.label} key={label.id}>
									{label.title}
								</span>
							))}
						</div>
					)}
				</div>
			</LinkChildren>
			{webinarDate.registrationLink && buttonVisible && (
				<div className={styles.buttonWrapper}>
					<Button type="link" size="small" href={webinarDate.registrationLink}>
						Register
					</Button>
				</div>
			)}
		</div>
	)
}
