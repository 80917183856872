import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import type { SocialLinkListResult } from '../data/SocialLinkListFragment'
import styles from './SocialLinks.module.sass'

export type SocialLinksProps = SocialLinkListResult & {
	isCircleBackground?: boolean
}

export const SocialLinks: FunctionComponent<SocialLinksProps> = ({ items, isCircleBackground = false }) => {
	return (
		<div className={styles.wrapper}>
			<ul className={styles.list}>
				{items.map(
					(item) =>
						item.socialLink?.url && (
							<li key={item.id} className={clsx(styles.item, isCircleBackground && styles.is_circleBackground)}>
								<Link className={styles.link} href={item.socialLink.url} target="_blank">
									{item.socialLink.icon && (
										<Image
											src={item.socialLink.icon.url}
											width={item.socialLink.icon.width}
											height={item.socialLink.icon.height}
											alt={item.socialLink.icon.alt ?? ''}
										/>
									)}
								</Link>
							</li>
						),
				)}
			</ul>
		</div>
	)
}
