import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
	type FunctionComponent,
	type ReactNode,
} from 'react'
import styles from './ScrollLock.module.sass'

const missingWrapperErrorMessage = 'Missing <ScrollLock> wrapper.'

const context = createContext<{
	isLocked: boolean
	lock: () => void
	unlock: () => void
}>({
	isLocked: false,
	lock: () => {
		throw new Error(missingWrapperErrorMessage)
	},
	unlock: () => {
		throw new Error(missingWrapperErrorMessage)
	},
})

export interface ScrollLockProps {
	children?: ReactNode
}

export const ScrollLock: FunctionComponent<ScrollLockProps> = ({ children }) => {
	const [isLocked, setIsLocked] = useState(false)
	const getWrapper = useCallback(() => document.documentElement, [])
	const contentRef = useRef<HTMLDivElement>(null)

	const value = useMemo(
		() => ({
			isLocked,
			lock: () => {
				setIsLocked(true)
			},
			unlock: () => {
				setIsLocked(false)
			},
		}),
		[isLocked],
	)

	useEffect(() => {
		const content = contentRef.current
		if (content === null) {
			return
		}
		const wrapper = getWrapper()
		if (isLocked) {
			const scrollTop = wrapper.scrollTop
			content.classList.add(styles.is_locked)
			content.scrollTop = scrollTop
			return () => {
				content.classList.remove(styles.is_locked)
				wrapper.scrollTop = scrollTop
			}
		}
	}, [getWrapper, isLocked])

	return (
		<context.Provider value={value}>
			<div ref={contentRef} className={styles.wrapper}>
				{children}
			</div>
		</context.Provider>
	)
}

export const useIsScrollLocked = () => useContext(context).isLocked
export const useLockScroll = () => useContext(context).lock
export const useUnlockScroll = () => useContext(context).unlock
export const useScrollLock = (locked: boolean) => {
	const lock = useLockScroll()
	const unlock = useUnlockScroll()

	useEffect(() => {
		if (locked) {
			lock()
			return () => {
				unlock()
			}
		}
	}, [lock, locked, unlock])
}
