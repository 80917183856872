/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ReferenceRendererProps } from '@contember/react-client'
import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { type CSSProperties, type FunctionComponent, type ReactNode, useMemo } from 'react'
import type { ContentReferenceType } from '../../generated/contember/zeus'
import type { ContentBlockResult } from '../data/ContentBlockFragment'
import type { ContentResult } from '../data/ContentFragment'
import type { ImageResult } from '../data/ImageFragment'
import { contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { isLocalUrl } from '../utilities/isLocalUrl'
import { useContentRendererCopyPasteBugWorkaround } from '../utilities/useContentRendererCopyPasteBugWorkaround'
import { ArticlePromoBox, GREY_BORDER_COLOR } from './ArticlePromoBox'
import { AuthorBox } from './AuthorBox'
import { ChangeTextToIcon, type ChangeTextToIconElement } from './ChangeTextToIcon'
import { ComposedBlock } from './ComposedBlock'
import { Container, type ContainerProps } from './Container'
import { ContentFile } from './ContentFile'
import { ContentLock } from './ContentLock'
import styles from './ContentRenderer.module.sass'
import { Embed } from './Embed'
import { Gallery } from './Gallery'
import { HighlightText, type HighlightTextElement } from './HighlightText'
import { ImageBlock } from './ImageBlock'
import { Note } from './Note'
import { Quote } from './Quote'
import { SubscribeBox } from './SubscribeBox'
import { SubtitleText, type SubtitleTextElement } from './SubtitleText'
import { TipBox } from './TipBox'
import { TipLink } from './TipLink'
import { Wysiwyg } from './Wysiwyg'

export interface ContentRendererProps {
	content: ContentResult
	containerDisableGutters?: boolean
	containerSize?: ContainerProps['size']
	disableBottomSpacing?: boolean
	deactivateLock?: () => void
	lockDeactivated?: boolean
}

type Block = ReferenceRendererProps<ContentBlockResult['references'][number]>

const standaloneTypes = ['reference']
const nestedTypes = ['listItem', 'anchor', 'tableCell', 'tableRow', 'scrollTarget', 'link']

type ReferenceRendererBlockProps = {
	[referenceType in ContentReferenceType]?: (block: Block) => ReactNode
}

const referenceRenderers = (
	containerSize?: ContainerProps['size'],
	containerDisableGutters?: ContentRendererProps['containerDisableGutters'],
	deactivateLock?: () => void,
	lockDeactivated?: boolean,
) => {
	const TikTokEmbed = dynamic(() => import('react-social-media-embed').then((mod) => mod.TikTokEmbed), { ssr: false })

	const blocks: ReferenceRendererBlockProps = {
		image: function image({ reference }) {
			return (
				reference.image && (
					<Container size={containerSize} disableGutters={containerDisableGutters}>
						<ImageBlock
							{...reference.image}
							sizes={`(min-width: ${
								containerSize === 'small'
									? '900px'
									: containerSize === 'normal'
									? '1200px'
									: containerSize === 'wide'
									? '1500px'
									: '100vw'
							},
								'100vw'`}
						/>
					</Container>
				)
			)
		},
		link: function link({ reference }) {
			return (
				reference.link && (
					<Container size={containerSize} disableGutters={containerDisableGutters}>
						<Link {...contemberLinkToHrefTargetRel(reference.link)}>{reference.link.title}</Link>
					</Container>
				)
			)
		},
		gallery: function gallery({ reference }) {
			return (
				reference.gallery?.items &&
				reference.gallery.items.length > 0 && (
					<Gallery items={reference.gallery.items.map((i) => i.image) as ImageResult[]} />
				)
			)
		},
		articlePromo: function articlePromo({ reference }) {
			return (
				reference.article && (
					<ArticlePromoBox
						text={reference.article.title}
						date={reference.article.publishedAt}
						readingTime={reference.article.readingTime}
						color={reference.companyColor?.code ?? reference.color}
						internalLink={reference.article.link}
						image={reference.article.tilePhoto}
					/>
				)
			)
		},
		articlePromoCustom: function articlePromoCustom({ reference }) {
			return (
				<ArticlePromoBox
					text={reference.primaryText}
					info={reference.secondaryText}
					color={reference.companyColor?.code ?? reference.color}
					link={reference.link}
					image={reference.image}
				/>
			)
		},
		authorBox: function authorBox({ reference }) {
			return reference.author && <AuthorBox author={reference.author} buttonText={reference.primaryText} />
		},
		composedBlock: function composedBlock({ reference }) {
			return (
				reference.composedBlock && (
					<ComposedBlock
						primaryBlock={reference.composedBlock.primaryBlock}
						secondaryBlock={reference.composedBlock.secondaryBlock}
					/>
				)
			)
		},
		html: function html({ reference }) {
			// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
			return reference.primaryText && <div dangerouslySetInnerHTML={{ __html: reference.primaryText }} />
		},
		line: function line({ reference }) {
			return (
				reference.color && (
					<div
						className={styles.line}
						style={
							{
								'--line-color': reference.color ?? GREY_BORDER_COLOR,
								'--line-width': reference.width === 'medium' ? '75%' : reference.width === 'default' ? '50%' : '100%',
							} as CSSProperties // Custom properties not supported workaround
						}
					/>
				)
			)
		},
		contentLock: function contentLock({ reference }) {
			return lockDeactivated ? undefined : (
				<ContentLock
					form={reference.form}
					title={reference.primaryText}
					buttonlabel={reference.secondaryText}
					deactivateLock={deactivateLock}
				/>
			)
		},
		file: function file({ reference }) {
			return (
				reference.file && (
					<Container size={containerSize} disableGutters={containerDisableGutters}>
						<ContentFile title={reference.primaryText} file={reference.file} form={reference.form} />
						{/* TODO: Add lock! */}
					</Container>
				)
			)
		},
		source: function source({ reference }) {
			return (
				reference.primaryText && (
					<Container size={containerSize} disableGutters={containerDisableGutters}>
						<div className={styles.sourceText}>{reference.primaryText}</div>
					</Container>
				)
			)
		},
		questionAnswer: function questionAnswer({ reference }) {
			return (
				reference.primaryText &&
				reference.jsonContent && (
					<Container size={containerSize} disableGutters={containerDisableGutters}>
						<div className={styles.question}>{reference.primaryText}</div>
						{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
						<div
							onClick={(e) => {
								const element = e.target as HTMLLinkElement
								if (element.parentElement?.tagName.toLowerCase() === 'a') {
									if (!isLocalUrl(element.parentElement.getAttribute('href') ?? '')) {
										element.parentElement.setAttribute('target', '_blank')
									}
								}
								if (element.tagName.toLowerCase() === 'a') {
									if (!isLocalUrl(element.getAttribute('href') ?? '')) {
										element.setAttribute('target', '_blank')
									}
								}
							}}
						>
							<RichTextRenderer source={reference.jsonContent} />
						</div>
					</Container>
				)
			)
		},
		note: function note({ reference }) {
			return (
				(reference.primaryText || reference.jsonContent) && (
					<Container size={containerSize} disableGutters={containerDisableGutters}>
						<Note
							title={reference.primaryText}
							text={reference.jsonContent}
							color={reference.color ?? undefined}
							link={reference.link}
						/>
					</Container>
				)
			)
		},
		embed: function embed({ reference }) {
			return (
				reference.embed && (
					<Container size={containerSize} disableGutters={containerDisableGutters}>
						<Embed {...reference.embed} />
					</Container>
				)
			)
		},
		tipBox: function tipBox({ reference, children }) {
			// @TODO: refactor - this is a workaround. Because the data might come from this "children" prop or "reference.jsonContent". The data should come only from "children" - so the data from "reference.jsonContent" should be moved to "children".
			const filteredChildren = (() => {
				if (children.props.children.length > 0) {
					return children.props.children.filter((child) => {
						if (child.props.leaf?.text === '') {
							return false
						}
						return true
					})
				}
				return undefined
			})()
			return (
				<TipBox
					content={filteredChildren}
					text={reference.jsonContent}
					link={reference.link}
					color={reference.companyColor?.code ?? reference.color}
					hasBackground={reference.backgroundFill}
					image={reference.image}
				/>
			)
		},
		subscribeBox: function subscribeBox({ reference }) {
			return (
				<SubscribeBox
					title={reference.primaryText}
					successNote={reference.jsonContent}
					mailingList={reference.mailingList}
					color={reference.color ?? undefined}
				/>
			)
		},
		text: function text({ reference }) {
			return reference.jsonContent
		},
		tipLink: function tipLink({ reference }) {
			return (
				<Container size={containerSize} disableGutters={containerDisableGutters}>
					{reference.primaryText && (
						<TipLink text={reference.primaryText} link={reference.link} color={reference.color ?? undefined} />
					)}
				</Container>
			)
		},
		tiktokLink: function tiktokLink({ reference }) {
			return (
				reference.primaryText && (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<TikTokEmbed url={reference.primaryText} width={325} />
					</div>
				)
			)
		},
		quote: function quote({ reference }) {
			return (
				<Container size={containerSize} disableGutters={containerDisableGutters}>
					{reference.jsonContent && (
						<Quote
							jsonContent={reference.jsonContent}
							type={reference.quoteType}
							color={reference.color ?? undefined}
							image={reference.image}
							author={reference.primaryText}
							role={reference.secondaryText}
							shareable={reference.shareable}
						/>
					)}
				</Container>
			)
		},
	}

	return blocks
}

type CustomElements = ChangeTextToIconElement | HighlightTextElement | SubtitleTextElement

export const ContentRenderer: FunctionComponent<ContentRendererProps> = ({
	content,
	containerDisableGutters = false,
	containerSize = 'normal',
	disableBottomSpacing = false,
	deactivateLock,
}) => {
	const blocks = useContentRendererCopyPasteBugWorkaround(content.blocks)

	return useMemo(
		() => (
			<div className={clsx(styles.wrapper, disableBottomSpacing && styles.is_disabledBottomSpacing)}>
				<RichTextRenderer
					blocks={blocks}
					sourceField="json"
					renderElement={(...args) => {
						const [params] = args
						const element = params.element as (typeof params)['element'] | CustomElements
						const { type } = element

						if (type === 'table') {
							return (
								<div className={clsx(styles.section, styles[`is_reference_${type}`])}>
									<Container size={containerSize} disableGutters={containerDisableGutters}>
										{params.fallback}
									</Container>
								</div>
							)
						}

						if (type === 'heading') {
							// biome-ignore lint/suspicious/noExplicitAny: <explanation>
							const heading = element as any

							const headingLevel = heading.level

							if (headingLevel === 1) {
								return <h1>{params.children}</h1>
							}
							if (headingLevel === 2) {
								return <h2>{params.children}</h2>
							}
							if (headingLevel === 3) {
								return <h3>{params.children}</h3>
							}
							if (headingLevel === 4) {
								return <h4>{params.children}</h4>
							}
							if (headingLevel === 5) {
								return <h5>{params.children}</h5>
							}
							return <div>{params.children}</div>
						}

						// Custom elements
						if (type === 'changeTextToIcon') {
							const elementChangeTextToIcon = element as ChangeTextToIconElement
							return <ChangeTextToIcon iconType={elementChangeTextToIcon.suchThat.iconType} />
						}
						if (type === 'highlightText') {
							const elementHighlightText = element as HighlightTextElement
							return (
								<HighlightText
									highlightTextType={elementHighlightText.suchThat.highlightTextType}
									fillColor={elementHighlightText.suchThat.fillColor}
								>
									{params.children}
								</HighlightText>
							)
						}
						if (type === 'subtitleText') {
							const elementSubtitleText = element as SubtitleTextElement
							return (
								<SubtitleText subtitleType={elementSubtitleText.suchThat.subtitleType}>{params.children}</SubtitleText>
							)
						}

						if (nestedTypes.includes(type)) {
							const reference = params.reference as ContentBlockResult['references'][number] // @TODO: remove cast
							if (params.referenceType === 'link' && reference.link) {
								return <Link {...contemberLinkToHrefTargetRel(reference.link)}>{params.children}</Link>
							}

							return params.fallback
						}

						if (standaloneTypes.includes(type)) {
							return (
								<div
									className={clsx(
										styles.section,
										params.referenceType && styles[`is_reference_${params.referenceType}`],
									)}
								>
									{type !== 'reference' || !params.referenceType || params.referenceType in referenceRenderers() ? (
										params.fallback
									) : (
										<Container size={containerSize} disableGutters={containerDisableGutters}>
											<div className={styles.notImplemented}>
												<div className={styles.notImplemented_name}>{params.referenceType}</div>
												is not yet implemented
											</div>
										</Container>
									)}
								</div>
							)
						}
						return (
							<div className={clsx(styles.section, styles.is_wysiwyg, styles[`is_${params.element.type}`])}>
								<Container size={containerSize} disableGutters={containerDisableGutters}>
									<Wysiwyg>{params.fallback}</Wysiwyg>
								</Container>
							</div>
						)
					}}
					referenceRenderers={referenceRenderers(containerSize, containerDisableGutters, deactivateLock)}
				/>
			</div>
		),
		[blocks, containerDisableGutters, containerSize, deactivateLock, disableBottomSpacing],
	)
}
