const localeCodes = {
	cs: 'cs-CZ',
	en: 'en-US',
} as const

export function ensureLocale(locale: string) {
	if (locale in localeCodes) {
		return locale as keyof typeof localeCodes
	}
	throw new Error('Unknown locale code')
}

export function parseDate(locale: keyof typeof localeCodes, date: string | Date, hasTime?: boolean) {
	date = date instanceof Date ? date : new Date(date)
	const localeCode = localeCodes[locale]
	const formatedDate = hasTime
		? date.toLocaleDateString(localeCode, {
				day: 'numeric',
				month: 'numeric',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
		  })
		: date.toLocaleDateString(localeCode, { day: 'numeric', month: 'numeric', year: 'numeric' })

	return { formatedDate }
}

export function formatDate(locale: keyof typeof localeCodes, date: string | Date, hasTime?: boolean) {
	const d = parseDate(locale, date, hasTime)

	return `${d.formatedDate}`
}
