export const ellipsedText = (text: string, lastCharacterVisiblePosition: number) => {
	const shortenedText = text.slice(0, lastCharacterVisiblePosition)
	const lastSpacePosition = shortenedText.lastIndexOf(' ')
	const output = `${shortenedText.slice(0, lastSpacePosition)} …`

	if (output.slice(-3) === '. …') {
		return shortenedText.slice(0, lastSpacePosition)
	}

	return output
}
