import type { FunctionComponent } from 'react'
import styles from './LoaderSpinner.module.sass'
import clsx from 'clsx'

export type LoaderSpinnerProps = {
	small?: boolean
}

export const LoaderSpinner: FunctionComponent<LoaderSpinnerProps> = ({ small }) => {
	return <span className={clsx(styles.loader, small && styles.loaderSmall)} />
}
