import clsx from 'clsx'
import type { ImageProps } from 'next/image'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import styles from './ResponsiveImage.module.sass'

export type ResponsiveImageProps = ImageProps & {
	isBorderRadius?: boolean
}

export const ResponsiveImage: FunctionComponent<ResponsiveImageProps> = ({ isBorderRadius = false, ...props }) => {
	return (
		<div
			className={clsx(styles.wrapper, isBorderRadius && styles.is_borderRadius, props.className && props.className)}
			style={{
				'--ResponsiveImage-image-width': props.width,
			}}
		>
			{/* eslint-disable-next-line jsx-a11y/alt-text */}
			<Image {...props} />
		</div>
	)
}
