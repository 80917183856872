import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import styles from './ChangeTextToIcon.module.sass'

const defaultIcons = { people: 'people', robot: 'robot' } as const
export type DefaultIconsProps = (typeof defaultIcons)[keyof typeof defaultIcons]

export type ChangeTextToIconProps = {
	iconType: DefaultIconsProps
}

export type ChangeTextToIconElement = {
	type: 'changeTextToIcon'
	suchThat: { iconType: DefaultIconsProps }
}

const sizes = {
	people: { width: 131, height: 73 },
	robot: { width: 73, height: 73 },
}

export const ChangeTextToIcon: FunctionComponent<ChangeTextToIconProps> = ({ iconType }) => {
	return (
		<span className={clsx(styles.wrapper, styles[`is_${iconType}`])}>
			<Image
				className={styles.image}
				src={`/images/${iconType}-icon.png`}
				width={sizes[iconType].width}
				height={sizes[iconType].height}
				alt={`${iconType} icon`}
			/>
		</span>
	)
}
