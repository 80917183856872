import type { FunctionComponent, ReactNode } from 'react'
import react from 'react'
type SiteContextProps = {
	siteCode: string
	baseUrl: string
}

const defaultValue: SiteContextProps = {
	siteCode: 'cz',
	baseUrl: '/cz',
}

export const SiteContext = react.createContext(defaultValue)

export const SiteContextProvider: FunctionComponent<{
	data: SiteContextProps
	children: ReactNode
}> = ({ data, children }) => {
	return <SiteContext.Provider value={data}>{children}</SiteContext.Provider>
}

export const useSite = () => {
	const { siteCode, baseUrl } = react.useContext(SiteContext)

	return { siteCode, baseUrl }
}
