import type { FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { Container } from './Container'
import { TileGrid } from './TileGrid'

export type MagazineCategoryPageProps = NonNullable<PageProps['page']['magazineCategoryPage']>

export const MagazineCategoryPage: FunctionComponent<MagazineCategoryPageProps> = ({
	magazineCategoryArticlePagination,
	link,
	title,
}) => {
	return (
		<Container size="normal">
			{link && <TileGrid articlePagination={magazineCategoryArticlePagination} categoryLink={link.url} title={title} />}
		</Container>
	)
}
