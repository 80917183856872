import { RichTextRenderer } from '@contember/react-client'
import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Container } from './Container'
import { useSite } from './contexts/SiteContextProvider'
import styles from './Footer.module.sass'
import { SocialLinks } from './SocialLinks'

export type FooterProps = NonNullable<PageProps['footer']>

export const Footer: FunctionComponent<FooterProps> = ({
	logo,
	links,
	copyright,
	copyrightSecondary,
	socialLinksList: socialLinks,
}) => {
	// Use contact if needed

	const { baseUrl } = useSite()

	return (
		<div className={styles.wrapper}>
			<Container size="wide">
				<div className={styles.main}>
					<div className={styles.columnMain}>
						{logo && (
							<Link className={styles.logo} href={baseUrl}>
								<Image
									className={styles.logoImage}
									src={logo.url}
									width={logo.width}
									height={logo.height}
									alt={logo.alt ?? ''}
								/>
							</Link>
						)}
					</div>
					{links && (
						<Columns
							columns={links}
							// Use contact if needed
							// contact={{
							// 	phone: phone ?? '',
							// 	email: email ?? '',
							// }}
						/>
					)}
				</div>
				<div className={styles.bottomBar}>
					{socialLinks?.items && <SocialLinks items={socialLinks.items} />}
					<div className={styles.copyrights}>
						{copyright && <div className={styles.copyright}>{copyright}</div>}
						{copyrightSecondary && (
							<div className={styles.copyright}>
								<RichTextRenderer source={copyrightSecondary} />
							</div>
						)}
					</div>
				</div>
			</Container>
		</div>
	)
}

type ColumnsProps = {
	columns: NonNullable<FooterProps['links']>
	// Use contact if needed
	// contact?: {
	// 	phone?: string
	// 	email?: string
	// }
}

const Columns: FunctionComponent<ColumnsProps> = ({ columns }) => {
	return (
		<div className={styles.columns}>
			{columns.map((column) => (
				<div key={column.id} className={styles.column}>
					{column.title && <h4 className={styles.columnTitle}>{column.title}</h4>}
					<ul className={styles.columnList}>
						{column.items.map(
							(item) =>
								item.link && (
									<li key={item.id} className={styles.columnItem}>
										<Link className={styles.columnItemLink} {...contemberLinkToHrefTargetRel(item.link)}>
											{item.link.title}
										</Link>
									</li>
								),
						)}
					</ul>
				</div>
			))}

			{/* Use contact if needed */}
			{/* {contact && (
				<div className={styles.column}>
					<h4 className={styles.columnTitle}>{translation('footer.column.contactLabel')}</h4>
					<ul className={styles.columnList}>
						{contact.email && (
							<li className={styles.columnItem}>
								<Link className={styles.columnItemLink} href={`mailto:${contact.email}`}>
									{contact.email}
								</Link>
							</li>
						)}
						{contact.phone && (
							<li className={styles.columnItem}>
								<Link className={styles.columnItemLink} href={`tel:${contact.phone}`}>
									{contact.phone}
								</Link>
							</li>
						)}
					</ul>
				</div>
			)} */}
		</div>
	)
}
