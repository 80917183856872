import type { FunctionComponent } from 'react'
import type { ProductNewsCategoryInfoResult } from '../data/ProductNewsCategoryFragment'
import styles from './AsideMenu.module.sass'
import { LinkChildren } from './LinkChildren'
import clsx from 'clsx'
import type { InternalLinkResult } from '../data/InternalLinkFragment'

export type AsideMenuProps = {
	page?: { heading?: string | undefined; link?: InternalLinkResult | undefined } | undefined | null
	heading?: string
	categories?: ProductNewsCategoryInfoResult[]
	activeCategoryId?: string | undefined
	months?: string[]
	setActiveMonth?: (month: string) => void
	activeMonth?: string | undefined
}

export const AsideMenu: FunctionComponent<AsideMenuProps> = ({
	months,
	page,
	heading,
	categories,
	activeCategoryId,
	setActiveMonth,
	activeMonth,
}) => {
	return (
		<div className={styles.wrapper}>
			{activeMonth ? (
				<h1
					className={clsx(styles.title, styles.titleEvent)}
					onClick={() => (setActiveMonth ? setActiveMonth('') : {})}
				>
					{heading}
				</h1>
			) : page?.heading ? (
				<LinkChildren type="internal" link={page?.link} className={styles.pageHeading}>
					<h1 className={styles.title}>{page?.heading}</h1>
				</LinkChildren>
			) : (
				<h1 className={styles.title}>{heading}</h1>
			)}

			<ul className={styles.links}>
				{categories && categories.length > 0
					? categories?.map(
							(cat) =>
								cat.paginateArticles.pageInfo.totalCount > 0 && (
									<LinkChildren
										type="internal"
										link={cat.link}
										key={cat.link?.url}
										className={clsx(styles.link, activeCategoryId === cat.id && styles.linkActive)}
									>
										<div className={clsx(styles.linkWrapper, activeCategoryId === cat.id && styles.linkWrapperActive)}>
											{cat.title}
										</div>
									</LinkChildren>
								),
					  )
					: months?.map((month) => (
							<button
								key={month}
								className={clsx(styles.button, activeMonth === month && styles.buttonActive)}
								onClick={() => (setActiveMonth ? setActiveMonth(month) : {})}
							>
								{month}
							</button>
					  ))}
			</ul>
		</div>
	)
}
