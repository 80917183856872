import clsx from 'clsx'
import { Fragment, type FunctionComponent } from 'react'
import { EventBox } from './EventBox'
import type { EventsPageProps } from './EventsPage'
import styles from './ItemList.module.sass'

export type ItemListEventsProps = {
	listEvent: EventsPageProps['listEvent']
	listMonths: string[]
	activeMonth?: string
}

export const ItemListEvents: FunctionComponent<ItemListEventsProps> = ({ activeMonth, listMonths, listEvent }) => {
	const eventsInActiveMonth = listEvent.filter((event) => event.eventYearAndMonth === activeMonth)

	const eventsInSelectedMonth = (month: string) =>
		listEvent
			.filter((event) => event.eventYearAndMonth === month)
			.sort((a, b) => Number(new Date(a.fromDate)) - Number(new Date(b.fromDate)))

	// @TODO: refactor - custom component
	const content = (event: ItemListEventsProps['listEvent'][number]) => (
		<EventBox
			event={{
				...event,
				formattedLongMonth: event.eventMonth,
			}}
		/>
	)

	// @TODO: refactor - custom component
	const eventItem = (event: ItemListEventsProps['listEvent'][number]) => (
		<li key={event.id} className={styles.eventLink}>
			{content(event)}
		</li>
	)
	return (
		<div className={styles.wrapper}>
			{activeMonth ? (
				<>
					<h2 className={clsx(styles.title, styles.titleEvents)}>{activeMonth}</h2>
					<ul className={clsx(styles.listEvents, styles.list)}>
						{eventsInActiveMonth.map((event) => eventItem(event))}
					</ul>
				</>
			) : (
				listMonths.map((month, index) => (
					<Fragment key={index}>
						<h2 className={clsx(styles.title, styles.titleEvents)}>{month}</h2>
						<ul className={clsx(styles.listEvents, styles.list)}>
							{eventsInSelectedMonth(month).map((event) => eventItem(event))}
						</ul>
					</Fragment>
				))
			)}
		</div>
	)
}
