import clsx from 'clsx'
import 'hiding-header/dist/style.css'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { FileResult } from '../data/FileFragment'
import { getFileType } from '../utilities/getFileType'
import { getSizeInMb } from '../utilities/getSizeInMb'
import styles from './FrontFile.module.sass'
import { Icon } from './Icon'
import { useTranslate } from './contexts/TranslationsContextProvider'

type FrontFileProps = {
	file: FileResult
}
export const FrontFile: FunctionComponent<FrontFileProps> = ({ file }) => {
	const translate = useTranslate()

	return (
		<a key={file.url} href={file.url} download={file.name} target="_blank" className={styles.link}>
			<div className={styles.wrapper}>
				{file.previewImage && (
					<div className={styles.imageWrapper}>
						<Image
							className={styles.image}
							src={file.previewImage.url}
							fill
							alt={file.previewImage.alt ?? ''}
							sizes="(min-width: 30rem) 30vw, (min-width: 48rem) 10vw, 50vw)"
						/>
					</div>
				)}
				<div className={styles.info}>
					<h3 className={styles.title}>{file.title}</h3>
					<div className={styles.fileContent}>
						<button className={clsx(styles.fileButton, styles.buttonItem)}>
							<div className={styles.fileIcon}>
								<Icon name="download" />
							</div>
							<div className={styles.fileButtonTitle}>{translate('file.download.button.link')}</div>
						</button>
						{file.type && <span className={clsx(styles.buttonItem, styles.fileType)}>{getFileType(file.type)}</span>}
						{file.size && <span className={clsx(styles.buttonItem, styles.fileSize)}>{getSizeInMb(file.size)}</span>}
					</div>
				</div>
			</div>
		</a>
	)
}
