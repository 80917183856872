import clsx from 'clsx'
import 'hiding-header/dist/style.css'
import type { FunctionComponent, InputHTMLAttributes } from 'react'
import styles from './Input.module.sass'

type InputProps = Pick<
	InputHTMLAttributes<HTMLInputElement>,
	'name' | 'type' | 'required' | 'onChange' | 'onFocus' | 'onBlur' | 'autoComplete' | 'placeholder' | 'value'
> & {
	hasIcon?: boolean
	formLocated?: boolean
	backgroundColor?: 'default' | 'white'
}
export const Input: FunctionComponent<InputProps> = ({
	name,
	onChange,
	required,
	placeholder,
	value,
	type,
	hasIcon,
	formLocated,
	backgroundColor = 'default',
}) => {
	return (
		<input
			className={clsx(
				styles.input,
				hasIcon && styles.inputSearch,
				formLocated && styles.inputFormLocated,
				styles[`is_backgroundColor_${backgroundColor}`],
			)}
			type={type ?? 'text'}
			name={name}
			value={value}
			placeholder={placeholder}
			onChange={onChange}
			required={required}
		/>
	)
}
