/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, type FunctionComponent } from 'react'
import type { PodcastCategoryInfoResult } from '../data/PodcastCategoryFragment'
import { api } from '../utilities/api'
import { ArticleBanner } from './ArticleBanner'
import { Button } from './Button'
import styles from './ItemList.module.sass'
import { LoadingSpinner } from './LoadingSpinner'
import { useSite } from './contexts/SiteContextProvider'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type ItemListPodcastProps = {
	listCategories?: PodcastCategoryInfoResult[]
	category?: PodcastCategoryInfoResult | null
	podcastArticlePagination?: any
}

export const ItemListPodcast: FunctionComponent<ItemListPodcastProps> = ({
	podcastArticlePagination,
	category,
	listCategories,
}) => {
	const { siteCode } = useSite()
	const translate = useTranslate()

	const query =
		podcastArticlePagination &&
		api.paginatePodcastCategoryArticle.useInfiniteQuery(
			{ siteCode, categoryLink: category?.link?.url },
			{
				getNextPageParam: (lastPage) => lastPage.nextCursor,
				initialCursor: podcastArticlePagination?.cursor,
				initialData: {
					pageParams: [podcastArticlePagination?.cursor],
					pages: [podcastArticlePagination],
				},
			},
		)

	const onClick = useCallback(() => {
		query.fetchNextPage()
	}, [query])

	const articles = query.data?.pages?.flatMap((page) => page.data.items) ?? []

	const articlesInCategory = (categoryId) => {
		return articles.filter((article) => article.podcastCategories.map((cat) => cat.id).includes(categoryId))
	}
	return (
		<div className={styles.wrapper}>
			{category ? (
				<>
					<h2 className={styles.title}>{category.title}</h2>
					<ul className={styles.list}>
						{articles.map((article) => (
							<li key={article.id}>
								<ArticleBanner article={article} />
							</li>
						))}
					</ul>
				</>
			) : listCategories ? (
				listCategories.map(
					(category) =>
						category.paginateArticles.pageInfo.totalCount > 0 && (
							<>
								<h2 className={styles.title}>{category.title}</h2>
								<ul className={styles.list}>
									{articlesInCategory(category.id).map((article) => (
										<li key={article.id}>
											<ArticleBanner article={article} />
										</li>
									))}
								</ul>
							</>
						),
				)
			) : (
				<ul className={styles.list}>
					{articles.map((article) => (
						<li key={article.id}>
							<ArticleBanner article={article} />
						</li>
					))}
				</ul>
			)}
			{query && query.hasNextPage && (
				<div className={styles.buttonWrapper}>
					<Button type="button" variant="secondary" onClick={onClick} disabled={query.isFetchingNextPage}>
						{translate('button.loadMore')}
						{query.isFetchingNextPage && <LoadingSpinner />}
					</Button>
				</div>
			)}
		</div>
	)
}
