/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useCallback, type FunctionComponent } from 'react'
import type { ArticleTileResult } from '../data/ArticleFragment'
import type { WikiCategoryInfoResult } from '../data/WikiCategoryFragment'
import { api } from '../utilities/api'
import { ArticleBanner } from './ArticleBanner'
import { Button } from './Button'
import styles from './ItemList.module.sass'
import { LoadingSpinner } from './LoadingSpinner'
import { useSite } from './contexts/SiteContextProvider'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type ItemListWikiProps = {
	listCategories: WikiCategoryInfoResult[]
	category?: WikiCategoryInfoResult | null
	wikiArticlePagination?: any
	wikiArticles?: ArticleTileResult[]
}

export const ItemListWiki: FunctionComponent<ItemListWikiProps> = ({
	wikiArticlePagination,
	category,
	wikiArticles,
	listCategories,
}) => {
	const { siteCode } = useSite()
	const translate = useTranslate()

	const query =
		wikiArticlePagination &&
		api.paginateWikiCategoryArticle.useInfiniteQuery(
			{ siteCode, categoryLink: category?.link?.url },
			{
				getNextPageParam: (lastPage) => lastPage.nextCursor,
				initialCursor: wikiArticlePagination.cursor,
				initialData: {
					pageParams: [wikiArticlePagination.cursor],
					pages: [wikiArticlePagination],
				},
			},
		)

	const onClick = useCallback(() => {
		query.fetchNextPage()
	}, [query])

	const articles = wikiArticlePagination ? query.data?.pages?.flatMap((page) => page.data.items) ?? [] : wikiArticles

	const articlesInCategory = (categoryId) => {
		return articles.filter((article) => article.wikiCategories.map((cat) => cat.id).includes(categoryId))
	}
	return (
		<div className={styles.wrapper}>
			{category ? (
				<>
					<h2 className={styles.title}>{category.title}</h2>
					<ul className={styles.list}>
						{articles.map((article) => (
							<li key={article.id}>
								<ArticleBanner article={article} />
							</li>
						))}
					</ul>
				</>
			) : (
				listCategories.map(
					(category) =>
						category.paginateArticles.pageInfo.totalCount > 0 && (
							<Fragment key={category.id}>
								<h2 className={styles.title}>{category.title}</h2>
								<ul className={styles.list}>
									{articlesInCategory(category.id).map((article) => (
										<li key={article.id}>
											<ArticleBanner article={article} />
										</li>
									))}
								</ul>
							</Fragment>
						),
				)
			)}
			{query && query.hasNextPage && (
				<div className={styles.buttonWrapper}>
					<Button type="button" variant="secondary" onClick={onClick} disabled={query.isFetchingNextPage}>
						{translate('button.loadMore')}
						{query.isFetchingNextPage && <LoadingSpinner />}
					</Button>
				</div>
			)}
		</div>
	)
}
