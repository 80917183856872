import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { ArticleTileResult } from '../data/ArticleFragment'
import { formatDate } from '../utilities/formatDate'
import styles from './VideoBanner.module.sass'

export interface VideoBannerProps {
	article: ArticleTileResult
	isFrontPageLocated?: boolean
}

export const VideoBanner: FunctionComponent<VideoBannerProps> = ({ article, isFrontPageLocated }) => {
	return (
		<div className={clsx(styles.wrapper, isFrontPageLocated && styles.wrapperFront)}>
			{article.tilePhoto && (
				<div className={clsx(styles.imageWrapper, isFrontPageLocated && styles.imageWrapperFront)}>
					<Image
						src={article.tilePhoto.url}
						fill
						alt={article.tilePhoto.alt ?? article.title ?? ''}
						className={styles.image}
					/>
				</div>
			)}
			{article.publishedAt && <div className={styles.date}>{formatDate('cs', article.publishedAt)}</div>}
			{article.title && <h3 className={styles.title}>{article.title}</h3>}
		</div>
	)
}
