import type { FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { AsideMenu } from './AsideMenu'
import { Container } from './Container'
import styles from './WikiPage.module.sass'
import { ItemListWiki } from './ItemListWiki'

export type WikiPageProps = NonNullable<PageProps['page']['wikiPage']>

export const WikiPage: FunctionComponent<WikiPageProps> = ({ heading, menuCategories, wikiArticles }) => {
	return (
		<Container size="normal">
			<div className={styles.wrapper}>
				<AsideMenu heading={heading} categories={menuCategories} />
				<Container size="small" disableGutters>
					<ItemListWiki wikiArticles={wikiArticles} listCategories={menuCategories} />
				</Container>
			</div>
		</Container>
	)
}
