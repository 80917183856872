import type { FunctionComponent } from 'react'
import type { EmbedResult } from '../data/EmbedFragment'
import styles from './Embed.module.sass'
import { Vimeo } from './Vimeo'
import { Youtube } from './Youtube'

export type EmbedProps = EmbedResult

export const Embed: FunctionComponent<EmbedProps> = ({ type, youtubeId, vimeoId, embedId }) => {
	const id = embedId ? embedId : type === 'youtube' ? youtubeId : type === 'vimeo' ? vimeoId : undefined
	return (
		<div className={styles.wrapper}>
			{type === 'youtube' && id ? <Youtube videoId={id} /> : type === 'vimeo' && id ? <Vimeo videoId={id} /> : null}
		</div>
	)
}
