import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { FileResult } from '../data/FileFragment'
import { getFileType } from '../utilities/getFileType'
import { getSizeInMb } from '../utilities/getSizeInMb'
import styles from './FileTile.module.sass'
import { Icon } from './Icon'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type FileTileProps = {
	file: FileResult
}

export const FileTile: FunctionComponent<FileTileProps> = ({ file }) => {
	const translation = useTranslate()

	return (
		<div className={styles.wrapper}>
			<a key={file.url} href={file.url} download={file.name} target="_blank" className={styles.link}>
				{file.previewImage && (
					<div className={styles.imageWrapper}>
						<Image
							className={styles.image}
							src={file.previewImage.url}
							fill
							alt={file.previewImage.alt ?? file.name}
							sizes="(min-width: 30rem) 75vw, (min-width: 48rem) 50vw, (min-width: 64rem) 25vw, 100vw)"
						/>
					</div>
				)}
				<h2 className={styles.fileTitle}>{file.title}</h2>

				<div className={styles.fileContent}>
					<button className={styles.fileButton}>
						<div className={styles.fileIcon}>
							<Icon name="download" />
						</div>
						<div className={styles.fileButtonTitle}>{translation('fileTile.button.label.download')}</div>
					</button>
					{file.type && <span className={styles.fileType}>{getFileType(file.type)}</span>}

					{file.size && <span className={styles.fileSize}>{getSizeInMb(file.size)}</span>}
				</div>
			</a>
		</div>
	)
}
