import clsx from 'clsx'
import 'hiding-header/dist/style.css'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { ArticleTileResult } from '../data/ArticleFragment'
import { formatDate } from '../utilities/formatDate'
import { getReadingTime } from '../utilities/getReadingTime'
import styles from './FrontArticleBanner.module.sass'
import { LinkChildren } from './LinkChildren'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type FrontArticleBannerProps = {
	article: ArticleTileResult
	objectFit?: 'cover' | 'contain'
	mobileLayout?: 'vertical' | 'horizontal'
}
export const FrontArticleBanner: FunctionComponent<FrontArticleBannerProps> = ({
	article,
	mobileLayout = 'vertical',
	objectFit = 'contain',
}) => {
	const translate = useTranslate()
	const content = (
		<div className={clsx(styles.wrapper, styles[`is_mobileLayout_${mobileLayout}`])}>
			<div className={styles.imageWrapper}>
				{article.productOwner?.image ? (
					<Image
						className={styles.image}
						src={article.productOwner.image.url}
						fill
						objectFit="contain"
						alt={article.productOwner.image.alt ?? ''}
					/>
				) : article.tilePhoto ? (
					<Image
						className={styles.image}
						src={article.tilePhoto.url}
						fill
						alt={article.tilePhoto.alt ?? ''}
						objectFit={objectFit}
						sizes="(min-width: 30rem) 75vw, (min-width: 48rem) 50vw, (min-width: 64rem) 25vw, 100vw)"
					/>
				) : null}
			</div>
			<div className={styles.content}>
				{article.title && <h3 className={styles.contentTitle}>{article.title}</h3>}
				<div className={styles.infoWrapper}>
					{article.publishedAt && <div className={styles.info}>{formatDate('cs', article.publishedAt)}</div>}
					{article.readingTime && article.readingTime > 0 && (
						<div className={styles.info}>
							{article.readingTime} {translate(`${getReadingTime(article.readingTime)}`)}
						</div>
					)}
				</div>
			</div>
		</div>
	)

	return article.link?.url ? (
		<LinkChildren link={article.link} type="internal" className={styles.link}>
			{content}
		</LinkChildren>
	) : (
		content
	)
}
