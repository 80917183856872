import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import type { CSSProperties, FunctionComponent } from 'react'
import type { ImageResult } from '../data/ImageFragment'
import type { InternalLinkResult } from '../data/InternalLinkFragment'
import type { LinkResult } from '../data/LinkFragment'
import { formatDate } from '../utilities/formatDate'
import { getReadingTime } from '../utilities/getReadingTime'
import styles from './ArticlePromoBox.module.sass'
import { LinkChildren } from './LinkChildren'
import { useTranslate } from './contexts/TranslationsContextProvider'

type ArticlePromoBoxProps = {
	color?: string
	text: string | undefined
	link?: LinkResult
	image?: ImageResult
	info?: string | undefined
	date?: string | undefined
	readingTime?: number | undefined
	internalLink?: InternalLinkResult
}

export const GREY_BORDER_COLOR = '#D7DADE'
export const ArticlePromoBox: FunctionComponent<ArticlePromoBoxProps> = ({
	text,
	date,
	readingTime,
	link,
	color,
	info,
	internalLink,
	image,
}) => {
	const translate = useTranslate()
	const content = (
		<div
			className={clsx(styles.wrapper, color && styles.borderColor, !image && styles.wrapperNoImage)}
			style={
				{
					'--article-promo-box-color': color ?? GREY_BORDER_COLOR,
				} as CSSProperties // Custom properties not supported workaround
			}
		>
			<div className={styles.content}>
				{text && <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />}
				{info && <div className={styles.info}>{info}</div>}
				{(date || readingTime) && (
					<div className={styles.info}>
						{date && formatDate('cs', date)}
						{readingTime && readingTime > 0 && (
							<span className={styles.readingTime}>
								{readingTime} {translate(`${getReadingTime(readingTime)}`)}
							</span>
						)}
					</div>
				)}
			</div>
			{image && (
				<div className={styles.imageWrapper}>
					<Image
						className={styles.image}
						src={image.url}
						alt={image.alt ?? ''}
						fill
						sizes="(min-width: 48rem) 40vw, 100vw)"
					/>
				</div>
			)}
		</div>
	)
	return link?.externalLink ? (
		<Link href={link.externalLink} target={link.isTargetBlank ? '_blank' : ''} className={styles.link}>
			{content}
		</Link>
	) : link?.internalLink || internalLink ? (
		<LinkChildren type="internal" link={internalLink || link?.internalLink} className={styles.link}>
			{content}
		</LinkChildren>
	) : (
		content
	)
}
