import type { FunctionComponent } from 'react'
import type { WebinarDateResult } from '../data/WebinarDateFragment'
import styles from './ItemList.module.sass'
import { WebinarDateBanner } from './WebinarDateBanner'
import clsx from 'clsx'
import { useTranslate } from './contexts/TranslationsContextProvider'
import { WebinarLoadingBox } from './WebinarLoadingBox'

export type ItemListWebinarsProps = {
	pastWebinarDates: WebinarDateResult[] | undefined
	openWebinarDates: WebinarDateResult[] | undefined
	futureWebinarDates: WebinarDateResult[] | undefined
	isLoading?: boolean
}

export const ItemListWebinars: FunctionComponent<ItemListWebinarsProps> = ({
	pastWebinarDates,
	openWebinarDates,
	futureWebinarDates,
	isLoading,
}) => {
	const LOADING_BOX_AMOUNT = 3

	const loadingBoxes = [...Array(LOADING_BOX_AMOUNT)].map((_, index) => <WebinarLoadingBox key={index} />)

	const translation = useTranslate()
	return (
		<div className={clsx(styles.wrapper, styles.wrapperWebinar)}>
			<div className={styles.group}>
				<h2 className={styles.webinarTitle}>{translation('webinarsPage.title.openWebinars')}</h2>
				<div className={styles.groupContent}>
					{isLoading ? (
						loadingBoxes
					) : openWebinarDates && openWebinarDates.length > 0 ? (
						openWebinarDates.map((webinarDate) => (
							<WebinarDateBanner key={webinarDate.id} webinarDate={webinarDate} buttonVisible />
						))
					) : (
						<div className={styles.noWebinars}>{translation('webinarsPage.noWebinars')}</div>
					)}
				</div>
			</div>

			<div className={styles.group}>
				<h2 className={styles.webinarTitle}>{translation('webinarsPage.title.futureWebinars')}</h2>
				<div className={styles.groupContent}>
					{isLoading ? (
						loadingBoxes
					) : futureWebinarDates && futureWebinarDates.length > 0 ? (
						futureWebinarDates.map((webinarDate) => (
							<WebinarDateBanner key={webinarDate.id} webinarDate={webinarDate} />
						))
					) : (
						<div className={styles.noWebinars}>{translation('webinarsPage.noWebinars')}</div>
					)}
				</div>
			</div>
			<div className={styles.group}>
				<h2 className={styles.webinarTitle}>{translation('webinarsPage.title.pastWebinars')}</h2>
				<div className={styles.groupContent}>
					{isLoading ? (
						loadingBoxes
					) : pastWebinarDates && pastWebinarDates.length > 0 ? (
						pastWebinarDates.map((webinarDate) => (
							<WebinarDateBanner key={webinarDate.id} webinarDate={webinarDate} isFiltered />
						))
					) : (
						<div className={styles.noWebinars}>{translation('webinarsPage.noWebinars')}</div>
					)}
				</div>
			</div>
		</div>
	)
}
