import 'hiding-header/dist/style.css'
import type { FunctionComponent } from 'react'
import { EventBox } from './EventBox'
import { FrontButton } from './FrontButton'
import styles from './FrontEvents.module.sass'
import { FrontHeader } from './FrontHeader'
import type { FrontPageProps } from './FrontPage'
import type { IconName } from './Icon'

type FrontEventsProps = {
	events: FrontPageProps['events']
	buttonLink: FrontPageProps['eventsPageUrl']
	buttonLabel: string
	title: string
	iconName?: IconName
}
export const FrontEvents: FunctionComponent<FrontEventsProps> = ({
	events,
	buttonLink,
	buttonLabel,
	title,
	iconName,
}) => {
	return (
		<div className={styles.wrapper}>
			<FrontHeader title={title} iconName={iconName} />

			<div className={styles.content}>
				{events.map((event) => (
					<EventBox
						event={{
							...event,
							formattedLongMonth: event.eventMonth,
						}}
						key={event.id}
					/>
				))}
			</div>
			{buttonLink && <FrontButton buttonLink={buttonLink} buttonLabel={buttonLabel} />}
		</div>
	)
}
