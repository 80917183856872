import { useState, type FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { AsideMenu } from './AsideMenu'
import { Container } from './Container'
import styles from './EventsPage.module.sass'
import { ItemListEvents } from './ItemListEvents'

export type EventsPageProps = NonNullable<PageProps['page']['eventsPage']>

export const EventsPage: FunctionComponent<EventsPageProps> = ({ heading, listEvent, listMonths }) => {
	const [activeMonth, setActiveMonth] = useState('')

	return (
		<Container size="normal">
			<div className={styles.wrapper}>
				<AsideMenu
					heading={heading}
					months={listMonths}
					activeCategoryId={activeMonth}
					setActiveMonth={(e) => setActiveMonth(e)}
					activeMonth={activeMonth}
				/>
				<Container size="small" disableGutters>
					<ItemListEvents activeMonth={activeMonth} listMonths={listMonths} listEvent={listEvent} />
				</Container>
			</div>
		</Container>
	)
}
