import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { Icon } from './Icon'
import styles from './Select.module.sass'

export type SelectProps = {
	onChange: (e) => void
	options: { value: string; label: string }[]
	placeholder?: string
}

export const Select: FunctionComponent<SelectProps> = ({ onChange, options }) => {
	return (
		<div className={styles.selectWrapper}>
			<select className={clsx(styles.inputSelect, styles.input)} onChange={onChange}>
				{options.map((o) => (
					<option key={o.value} value={o.value}>
						{o.label}
					</option>
				))}
			</select>
			<div className={styles.icon}>
				<Icon name="chevron" />
			</div>
		</div>
	)
}
