import type { ImageProps } from 'next/image'
import type { FunctionComponent } from 'react'
import type { ImageResult } from '../data/ImageFragment'
import styles from './ImageBlock.module.sass'
import { ResponsiveImage } from './ResponsiveImage'

export type ImageBlockProps = ImageResult & Pick<ImageProps, 'sizes'>

export const ImageBlock: FunctionComponent<ImageBlockProps> = ({ url, width, height, alt, sizes }) => {
	return (
		<div className={styles.wrapper}>
			<div
				className={styles.imageWrapper}
				style={{
					'--ImageBlock-image-width': width,
				}}
			>
				<ResponsiveImage
					className={styles.image}
					src={url}
					width={width}
					height={height}
					alt={alt ?? ''}
					sizes={sizes}
					isBorderRadius
				/>
			</div>
			{alt && <div className={styles.alt}>{alt}</div>}
		</div>
	)
}
