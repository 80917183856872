import type { FunctionComponent } from 'react'
//
import ReactModal from 'react-modal'
import style from './Modal.module.sass'
import { useScrollLock } from './ScrollLock'

if (typeof window !== 'undefined') {
	ReactModal.setAppElement('body')
}

export type ModalProps = ReactModal['props'] & {
	close: () => void
	onAfterOpen?: () => void
	type?: 'default' | 'menu' | 'search' | 'info' | 'filter'
	onlyHiddenWithInput?: boolean
	rendered?: boolean
}

export const Modal: FunctionComponent<ModalProps> = ({
	type = 'default',
	close,
	children,
	onAfterOpen,
	rendered,
	...props
}) => {
	useScrollLock(props.isOpen)

	return (
		<ReactModal
			onAfterOpen={onAfterOpen}
			{...props}
			overlayClassName={{
				base: props.isOpen ? style.overlay : rendered ? style.overlayHidden : style.overlay,
				afterOpen: style.is_afterOpen,
				beforeClose: style.is_beforeClose,
			}}
			appElement={typeof document !== 'undefined' ? (document.getElementById('root') as HTMLElement) : undefined}
			bodyOpenClassName={style.is_modalOpen}
			portalClassName={style.portal}
			shouldCloseOnEsc={true}
			ariaHideApp={!rendered ?? undefined}
			role="dialog"
			contentLabel={rendered ? 'Vyhledávací modal' : undefined}
			style={{
				content: {
					margin: 'auto',
					borderRadius: '10px',
					border: 'none',
					padding: '16px 40px 20px',
					height: 'fit-content',
					maxHeight: '92vh',
					width: '100%',
					maxWidth: type === 'menu' ? '100%' : '600px',
				},
			}}
			isOpen={type === 'search' ? true : props.isOpen}
			shouldCloseOnOverlayClick={true}
			onRequestClose={close}
		>
			<div className={style.main}>{children}</div>
		</ReactModal>
	)
}
