import type { FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import styles from './GenericPage.module.sass'

export type GenericPageProps = NonNullable<PageProps['page']['genericPage']>

export const GenericPage: FunctionComponent<GenericPageProps> = ({ content }) => {
	return (
		<div className={styles.wrapper}>
			<Container size="small">{content && <ContentRenderer content={content} />}</Container>
		</div>
	)
}
