/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx'
import { useCallback, type FunctionComponent } from 'react'
import type { TvCategoryInfoResult } from '../data/TvCategoryFragment'
import { api } from '../utilities/api'
import { Button } from './Button'
import styles from './ItemList.module.sass'
import { LinkChildren } from './LinkChildren'
import { LoadingSpinner } from './LoadingSpinner'
import { VideoBanner } from './VideoBanner'
import { useSite } from './contexts/SiteContextProvider'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type ItemListTvProps = {
	listCategories?: TvCategoryInfoResult[]
	category?: TvCategoryInfoResult | null
	tvArticlePagination?: any
}

export const ItemListTv: FunctionComponent<ItemListTvProps> = ({ tvArticlePagination, category, listCategories }) => {
	const { siteCode } = useSite()
	const translate = useTranslate()

	const query =
		tvArticlePagination &&
		api.paginateTvCategoryArticle.useInfiniteQuery(
			{ siteCode, categoryLink: category?.link?.url },
			{
				getNextPageParam: (lastPage) => lastPage.nextCursor,
				initialCursor: tvArticlePagination.cursor,
				initialData: {
					pageParams: [tvArticlePagination.cursor],
					pages: [tvArticlePagination],
				},
			},
		)

	const onClick = useCallback(() => {
		query.fetchNextPage()
	}, [query])

	const articles = query.data?.pages?.flatMap((page) => page.data.items) ?? []

	const articlesInCategory = (categoryId) => {
		return articles.filter((article) => article.tvCategories.map((cat) => cat.id).includes(categoryId))
	}
	return (
		<div className={styles.wrapper}>
			{category ? (
				<>
					<h2 className={styles.title}>{category.title}</h2>
					<ul className={clsx(styles.list, styles.listTv)}>
						{articles.map((article) => (
							<li key={article.id}>
								<LinkChildren type="internal" link={article.link}>
									<VideoBanner article={article} />
								</LinkChildren>
							</li>
						))}
					</ul>
				</>
			) : listCategories ? (
				listCategories.map(
					(category) =>
						category.paginateArticles.pageInfo.totalCount > 0 && (
							<>
								<h2 className={styles.title}>{category.title}</h2>
								<ul className={clsx(styles.list, styles.listTv)}>
									{articlesInCategory(category.id).map((article) => (
										<li key={article.id}>
											<LinkChildren type="internal" link={article.link}>
												<VideoBanner article={article} />
											</LinkChildren>
										</li>
									))}
								</ul>
							</>
						),
				)
			) : (
				<ul className={clsx(styles.list, styles.listTv)}>
					{articles.map((article) => (
						<li key={article.id}>
							<LinkChildren type="internal" link={article.link}>
								<VideoBanner article={article} />
							</LinkChildren>
						</li>
					))}
				</ul>
			)}
			{query && query.hasNextPage && (
				<div className={styles.buttonWrapper}>
					<Button type="button" variant="secondary" onClick={onClick} disabled={query.isFetchingNextPage}>
						{translate('button.loadMore')}
						{query.isFetchingNextPage && <LoadingSpinner />}
					</Button>
				</div>
			)}
		</div>
	)
}
