import Image from 'next/image'
import type { FunctionComponent } from 'react'
import styles from './NoFileBanner.module.sass'
import { useTranslate } from './contexts/TranslationsContextProvider'

export const NoFileBanner: FunctionComponent = () => {
	const translate = useTranslate()
	return (
		<div className={styles.wrapper}>
			<Image src="/images/noFiles.png" width={90} height={120} alt="No results" />
			<div className={styles.title}>{translate('noResults.title')}</div>
		</div>
	)
}
