import clsx from 'clsx'
import { useEffect, type FunctionComponent, type ReactNode } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { Footer } from './Footer'
import { Header } from './Header'
import styles from './Layout.module.sass'
import { MagazineHeader } from './MagazineHeader'
import { useSite } from './contexts/SiteContextProvider'

const LmcCookieConsentManagerPromise = typeof window === 'undefined' ? null : import('@lmc-eu/cookie-consent-manager')

function deleteAllCookies() {
	const cookies = document.cookie.split(';')
	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i]
		const eqPos = cookie.indexOf('=')
		const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.google.com;'
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=www.google.com;'
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.fonts.net;'
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.almacareer.com;'
	}
}

let isPersonalisationOnPageLoadAllowedResolve = (_: boolean) => {}
export const isPersonalisationOnPageLoadAllowed = new Promise<boolean>((resolve) => {
	isPersonalisationOnPageLoadAllowedResolve = (allowed) => resolve(allowed)
})

let isPersonalisationAllowedResolve = () => {}
export const isPersonalisationAllowed = new Promise<boolean>((resolve) => {
	isPersonalisationAllowedResolve = () => resolve(true)
})

export type LayoutProps = {
	currentPageType: PageProps['globalContext']['currentPageType']
	header: PageProps['header']
	magazineHeader: PageProps['magazineHeader']
	children: ReactNode
	footer: PageProps['footer']
	secondaryFooter: string
}

export const Layout: FunctionComponent<LayoutProps> = ({
	currentPageType,
	children,
	footer,
	header,
	magazineHeader,
	secondaryFooter,
}) => {
	const { siteCode } = useSite()

	const shortLang = siteCode === 'cz' ? 'cs' : siteCode

	useEffect(() => {
		if (typeof window !== 'undefined') {
			LmcCookieConsentManagerPromise?.then(({ default: LmcCookieConsentManager }) => {
				if (localStorage.getItem('clearCookiesAfterReload') === 'true') {
					deleteAllCookies()
					localStorage.removeItem('clearCookiesAfterReload')
				}

				const cookieConsent = LmcCookieConsentManager('magazin.almacareer.com', {
					defaultLang: shortLang,
					autodetectLang: false,
					onAccept: (cookieConsent) => {
						if (cookieConsent.allowedCategory('personalization')) {
							isPersonalisationAllowedResolve()
						}
					},
					onChange: () => {
						localStorage.setItem('clearCookiesAfterReload', 'true')
						deleteAllCookies()
						location.reload()
					},
				})
				isPersonalisationOnPageLoadAllowedResolve(cookieConsent.allowedCategory('personalization'))
			})
		}
	}, [shortLang])

	return (
		<div className={clsx(styles.wrapper, styles[`is_site_${siteCode}`], styles[`is_${currentPageType}`])}>
			{header && <Header {...header} />}
			{magazineHeader && <MagazineHeader {...magazineHeader} />}
			<div className={styles.content}>{children}</div>

			{footer && (
				<div className={styles.footer}>
					<Footer {...footer} />
				</div>
			)}
			{secondaryFooter && (
				<div className={styles.secondaryFooter}>
					<span dangerouslySetInnerHTML={{ __html: secondaryFooter }} />
				</div>
			)}
		</div>
	)
}
