/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, type FunctionComponent } from 'react'
import type { ProductNewsCategoryInfoResult } from '../data/ProductNewsCategoryFragment'
import { api } from '../utilities/api'
import { ArticleBanner } from './ArticleBanner'
import { Button } from './Button'
import styles from './ItemList.module.sass'
import { LoadingSpinner } from './LoadingSpinner'
import { useSite } from './contexts/SiteContextProvider'
import { useTranslate } from './contexts/TranslationsContextProvider'
import type { ArticleTileResult } from '../data/ArticleFragment'

export type ItemListProductNewsProps = {
	listCategories: ProductNewsCategoryInfoResult[]
	category?: ProductNewsCategoryInfoResult | null
	productNewsArticlesPagination?: any
	productNewsArticles?: ArticleTileResult[]
}

export const ItemListProductNews: FunctionComponent<ItemListProductNewsProps> = ({
	productNewsArticlesPagination,
	category,
	listCategories,
	productNewsArticles,
}) => {
	const { siteCode } = useSite()
	const translate = useTranslate()

	const query =
		productNewsArticlesPagination &&
		api.paginateProductNewsCategoryArticle.useInfiniteQuery(
			{ siteCode, categoryLink: category?.link?.url },
			{
				getNextPageParam: (lastPage) => lastPage.nextCursor,
				initialCursor: productNewsArticlesPagination?.cursor,
				initialData: {
					pageParams: [productNewsArticlesPagination?.cursor],
					pages: [productNewsArticlesPagination],
				},
			},
		)

	const onClick = useCallback(() => {
		query.fetchNextPage()
	}, [query])

	const articles = productNewsArticlesPagination
		? query.data?.pages?.flatMap((page) => page.data.items) ?? []
		: productNewsArticles

	const articlesInCategory = (categoryId) => {
		return articles.filter((article) => article.productNewsCategories.map((cat) => cat.id).includes(categoryId))
	}
	return (
		<div className={styles.wrapper}>
			{category ? (
				<>
					<h2 className={styles.title}>{category.title}</h2>
					<ul className={styles.list}>
						{articles.map((article) => (
							<li key={article.id}>
								<ArticleBanner article={article} />
							</li>
						))}
					</ul>
				</>
			) : (
				listCategories.map(
					(category) =>
						category.paginateArticles.pageInfo.totalCount > 0 && (
							<>
								<h2 className={styles.title}>{category.title}</h2>
								<ul className={styles.list}>
									{articlesInCategory(category.id).map((article) => (
										<li key={article.id}>
											<ArticleBanner article={article} />
										</li>
									))}
								</ul>
							</>
						),
				)
			)}
			{query && query.hasNextPage && (
				<div className={styles.buttonWrapper}>
					<Button type="button" variant="secondary" onClick={onClick} disabled={query.isFetchingNextPage}>
						{translate('button.loadMore')}
						{query.isFetchingNextPage && <LoadingSpinner />}
					</Button>
				</div>
			)}
		</div>
	)
}
