import clsx from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'
import styles from './HighlightText.module.sass'
import { Icon } from './Icon'

const defaultHighlightTextType = {
	rainbowWave: 'rainbowWave',
	pencilUnderline: 'pencilUnderline',
	pencilCircle: 'pencilCircle',
	pencilCircle2: 'pencilCircle2',
	arrowUp: 'arrowUp',
	arrowLifeCycle: 'arrowLifeCycle',
	dashedArrow: 'dashedArrow',
} as const
type DefaultHighlightTextTypeProps = (typeof defaultHighlightTextType)[keyof typeof defaultHighlightTextType]

export type HighlightTextProps = {
	highlightTextType: DefaultHighlightTextTypeProps
	fillColor?: string
	children: ReactNode
}

export type HighlightTextElement = {
	type: 'highlightText'
	suchThat: {
		highlightTextType: DefaultHighlightTextTypeProps
		fillColor: string
	}
}

export const HighlightText: FunctionComponent<HighlightTextProps> = ({ highlightTextType, fillColor, children }) => {
	return (
		<span
			className={clsx(styles.wrapper, styles[`is_${highlightTextType}`])}
			style={{
				'--HighlightText-fillColor': `${fillColor}`,
			}}
		>
			<span className={styles.content}>
				{children}
				{highlightTextType === 'pencilCircle' ? (
					<span className={styles.highlight}>
						<Icon name="pencilCircle" preserveAspectRatio={false} />
					</span>
				) : highlightTextType === 'pencilCircle2' ? (
					<span className={styles.highlight}>
						<Icon name="pencilCircle2" preserveAspectRatio={false} />
					</span>
				) : null}
			</span>
			{highlightTextType === 'rainbowWave' ? (
				<span className={styles.highlight}>
					<Icon name="rainbowWave" preserveAspectRatio={false} />
				</span>
			) : highlightTextType === 'pencilUnderline' ? (
				<span className={styles.highlight}>
					<Icon name="pencilUnderline" preserveAspectRatio={false} />
				</span>
			) : highlightTextType === 'arrowLifeCycle' ? (
				<span className={styles.highlight}>
					<Icon name="arrowLifeCycle" preserveAspectRatio={false} />
				</span>
			) : highlightTextType === 'arrowUp' ? (
				<span className={styles.highlight}>
					<Icon name="arrowUp" preserveAspectRatio={false} />
				</span>
			) : highlightTextType === 'dashedArrow' ? (
				<span className={styles.highlight}>
					<Icon name="dashedArrow" preserveAspectRatio={false} />
				</span>
			) : null}
		</span>
	)
}
