/* eslint-disable @typescript-eslint/no-explicit-any */
import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { type CSSProperties, type FunctionComponent, type ReactNode, Children } from 'react'
import type { ImageResult } from '../data/ImageFragment'
import type { LinkResult } from '../data/LinkFragment'
import { LinkChildren } from './LinkChildren'
import styles from './TipBox.module.sass'

type TipBoxProps = {
	hasBackground?: boolean
	color?: string
	text: string | undefined
	link?: LinkResult
	image?: ImageResult
	content?: ReactNode
}

export const TipBox: FunctionComponent<TipBoxProps> = ({ text, link, color, hasBackground, image, content }) => {
	const body = (
		<div
			className={clsx(styles.wrapper, color && styles.borderColor, hasBackground && styles.hasBackground)}
			style={
				{
					'--tip-box-color': color,
				} as CSSProperties // Custom properties not supported workaround
			}
		>
			{image && (
				<div className={styles.imageWrapper}>
					<Image
						className={styles.image}
						src={image.url}
						alt={image.alt ?? ''}
						fill
						sizes="(min-width: 48rem) 50vw, 100vw)"
					/>
				</div>
			)}
			{Children.count(content) !== 0 ? (
				<div className={clsx(styles.text, hasBackground && styles.tipBoxText)}>{content}</div>
			) : (
				text && (
					<div className={clsx(styles.text, hasBackground && styles.tipBoxText)}>
						<div
							onClick={(e) => {
								const element = e.target as HTMLLinkElement
								if (element.parentElement?.tagName.toLowerCase() === 'a') {
									element.setAttribute('target', '_blank')
									hasBackground && element.parentElement.style.color === 'white'
								}
								if (element.tagName.toLowerCase() === 'a') {
									element.setAttribute('target', '_blank')
								}
							}}
						>
							<RichTextRenderer source={text} />
						</div>
					</div>
				)
			)}
		</div>
	)
	return link?.externalLink ? (
		<Link href={link.externalLink} target={link.isTargetBlank ? '_blank' : ''} className={styles.link}>
			{body}
		</Link>
	) : link?.internalLink ? (
		<LinkChildren type="internal" link={link.internalLink} className={styles.link}>
			{body}
		</LinkChildren>
	) : (
		body
	)
}
