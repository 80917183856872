import { RichTextRenderer } from '@contember/react-client'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import type { LinkResult } from '../data/LinkFragment'
import styles from './Note.module.sass'

export type NoteProps = {
	text: string | undefined
	color: string | undefined
	title: string | undefined
	link: LinkResult | undefined
}

export const Note: FunctionComponent<NoteProps> = ({ text, title, color, link }) => {
	const content = (
		<div className={styles.wrapper} style={{ borderColor: color }}>
			<h4 className={styles.title}>{title}</h4>
			{text && <RichTextRenderer source={text} />}
		</div>
	)
	if (link) {
		return (
			<Link
				href={link.externalLink ?? link.internalLink?.url ?? ''}
				target={link.isTargetBlank ? '_blank' : ''}
				className={styles.link}
			>
				{content}
			</Link>
		)
	} else {
		return content
	}
}
