import clsx from 'clsx'
import Link from 'next/link'
import type { CSSProperties, FunctionComponent } from 'react'
import type { LinkResult } from '../data/LinkFragment'
import { Icon } from './Icon'
import { LinkChildren } from './LinkChildren'
import styles from './TipLink.module.sass'

type TipLinkProps = {
	color?: string
	text: string
	link?: LinkResult
}

export const TipLink: FunctionComponent<TipLinkProps> = ({ text, link, color }) => {
	const content = (
		<div
			className={clsx(styles.wrapper, color && styles.borderColor)}
			style={
				{
					'--tip-box-color': color,
				} as CSSProperties // Custom properties not supported workaround
			}
		>
			{text}
			{link && (
				<div className={styles.icon}>
					<Icon name="arrowLink" />
				</div>
			)}
		</div>
	)
	return link?.externalLink ? (
		<Link href={link.externalLink} target={link.isTargetBlank ? '_blank' : ''} className={styles.link}>
			{content}
		</Link>
	) : link?.internalLink ? (
		<LinkChildren type="internal" link={link.internalLink} className={styles.link}>
			{content}
		</LinkChildren>
	) : (
		content
	)
}
