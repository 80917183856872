import type { FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { Container } from './Container'
import { FileList } from './FileList'
import styles from './FilesPage.module.sass'

export type FilesPageProps = NonNullable<PageProps['page']['filesPage']>

export const FilesPage: FunctionComponent<FilesPageProps> = ({ heading, filePagination }) => {
	return (
		<Container size="normal">
			<div className={styles.wrapper}>
				<Container size="wide">
					<h1 className={styles.heading}>{heading}</h1>

					<FileList filePagination={filePagination} />
				</Container>
			</div>
		</Container>
	)
}
