import { useRouter } from 'next/router'
import { useEffect, useState, type FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { api } from '../utilities/api'
import { toArray } from '../utilities/arrayHelper'
import { roundTheCurrentTimeToMinutes } from '../utilities/roundTheCurrentTimeToMinutes'
import { CheckboxGroup } from './CheckboxGroup'
import { Container } from './Container'
import { ItemListWebinars } from './ItemListWebinars'
import { WebinarSelect } from './WebinarSelect'
import styles from './WebinarsPage.module.sass'
import { useSite } from './contexts/SiteContextProvider'

export type WebinarsPageProps = NonNullable<PageProps['page']['webinarsPage']>

export const WebinarsPage: FunctionComponent<WebinarsPageProps> = ({
	heading,
	pastWebinarDates,
	openWebinarDates,
	futureWebinarDates,
	listWebinarLabel,
	listWebinarCategory,
	link,
}) => {
	const router = useRouter()

	const [labels, setLabel] = useState({ label: [] as string[] })
	const [categories, setCategory] = useState({ category: [] as string[] })

	useEffect(() => {
		setLabel({
			label: toArray(router.query.label),
		})
		setCategory({
			category: toArray(router.query.category),
		})
	}, [router])

	const { siteCode } = useSite()
	const filteredDates = api.listWebinarDate.useQuery({
		siteCode,
		categories: categories?.category,
		labels: labels?.label,
	})

	const isFiltered =
		(categories.category && categories.category.length > 0) || (labels.label && labels.label.length > 0)

	const futureDates = isFiltered
		? filteredDates.data?.listWebinarDate.filter(
				(date) => date.date && date.date >= roundTheCurrentTimeToMinutes() && !date.isOpenForRegistration,
		  )
		: futureWebinarDates

	const pastDates = isFiltered
		? filteredDates.data?.listWebinarDate.filter((date) => date.date && date.date < roundTheCurrentTimeToMinutes())
		: pastWebinarDates

	const openDates = isFiltered
		? filteredDates.data?.listWebinarDate.filter(
				(date) => date.date && date.date >= roundTheCurrentTimeToMinutes() && date.isOpenForRegistration,
		  )
		: openWebinarDates

	return (
		<Container size="normal">
			<div className={styles.wrapper}>
				<Container size="wide" disableGutters>
					<h1 className={styles.heading}>{heading}</h1>
					<div className={styles.filter}>
						{listWebinarLabel && listWebinarLabel.length > 0 && (
							<div className={styles.labelGroup}>
								<CheckboxGroup items={listWebinarLabel} label="label" initialQuery={labels} url={link?.url} />
							</div>
						)}
						<div className={styles.selectWrapper}>
							<WebinarSelect
								items={listWebinarCategory}
								initialQuery={categories}
								label="category"
								categories={categories.category}
								url={link?.url}
							/>
						</div>
					</div>
					<ItemListWebinars
						isLoading={filteredDates.isLoading}
						pastWebinarDates={pastDates}
						futureWebinarDates={futureDates}
						openWebinarDates={openDates}
					/>
				</Container>
			</div>
		</Container>
	)
}
