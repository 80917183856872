import type { FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { AsideMenu } from './AsideMenu'
import { Container } from './Container'
import styles from './TvPage.module.sass'
import { ItemListTv } from './ItemListTv'

export type TvPageWithCategoryProps = NonNullable<PageProps['page']['tvPageWithCategory']>

export const TvPageWithCategory: FunctionComponent<TvPageWithCategoryProps> = ({
	page,
	category,
	menuCategories,
	tvArticlePagination,
}) => {
	return (
		<Container size="normal">
			<div className={styles.wrapper}>
				<AsideMenu page={page} categories={menuCategories} activeCategoryId={category?.id} />
				<Container size="small" disableGutters>
					<ItemListTv category={category} listCategories={menuCategories} tvArticlePagination={tvArticlePagination} />
				</Container>
			</div>
		</Container>
	)
}
