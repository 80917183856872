import clsx from 'clsx'
import React from 'react'
import style from './CheckboxGroup.module.sass'
import { Icon } from './Icon'

type CheckboxProps = {
	checked: boolean
	label: string
	defaultChecked?: boolean
	disabled?: boolean
	name?: string
	onChange: React.ChangeEventHandler<HTMLInputElement>
	value?: string
}

export const Checkbox = React.memo(function Checkbox(props: CheckboxProps) {
	return (
		<label className={clsx(style.label, props.checked && style.labelChecked, props.disabled && style.labelDisabled)}>
			{props.checked && (
				<span className={style.icon}>
					<Icon name="check" />
				</span>
			)}
			<input
				className={style.checkbox}
				type="checkbox"
				checked={props.checked}
				name={props.name}
				onChange={props.onChange}
				defaultChecked={props.defaultChecked}
				disabled={props.disabled}
			/>
			{props.label}
		</label>
	)
})
