import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { AuthorResult } from '../data/AuthorFragmnet'
import styles from './AuthorBox.module.sass'
import { Button } from './Button'

type AuthorBoxProps = {
	author: AuthorResult
	buttonText?: string
}

export const AuthorBox: FunctionComponent<AuthorBoxProps> = ({ author, buttonText }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				{author.image && (
					<div className={styles.imageWrapper}>
						<Image
							className={styles.image}
							src={author.image.url}
							alt={author.image.alt ?? ''}
							fill
							sizes="(min-width: 30rem) 10vw, 50vw)"
						/>
					</div>
				)}
				<div className={styles.name}>{author.name}</div>
				<div className={styles.role}>{author.role}</div>
			</div>
			<div className={styles.descriptionWrapper}>
				{author.description && <div className={styles.text}>{author.description}</div>}
				{buttonText && author.email && (
					<Button type="link" href={`mailto:${author.email}`} size="small">
						{buttonText}
					</Button>
				)}
			</div>
		</div>
	)
}
