/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, type FunctionComponent } from 'react'
import { api } from '../utilities/api'
import { Button } from './Button'
import { LoadingSpinner } from './LoadingSpinner'
import { TileBanner } from './TileBanner'
import styles from './TileGrid.module.sass'
import { Tiles } from './Tiles'
import { useSite } from './contexts/SiteContextProvider'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type TileGridProps = {
	articlePagination: any
	categoryLink?: string
	title?: string
}

export const TileGrid: FunctionComponent<TileGridProps> = ({ articlePagination, categoryLink, title }) => {
	const { siteCode } = useSite()
	const translate = useTranslate()

	const queryApi = categoryLink ? api.paginateMagazineCategoryArticle : api.paginateMagazineArticle
	const query = queryApi.useInfiniteQuery(
		{ siteCode, categoryLink },
		{
			getNextPageParam: (lastPage) => lastPage.nextCursor,
			initialCursor: articlePagination.cursor,
			initialData: {
				pageParams: [articlePagination.cursor],
				pages: [articlePagination],
			},
		},
	)

	const onClick = useCallback(() => {
		query.fetchNextPage()
	}, [query])

	const articles = useMemo(() => query.data?.pages?.flatMap((page) => page.data.items) ?? [], [query.data?.pages])

	const [firstArticle, ...restArticles] = articles

	return (
		<div className={styles.wrapper}>
			{categoryLink ? (
				<>
					<h1 className={styles.title}>{title}</h1>
					{articles.length > 0 && <Tiles items={articles} isFirstArticlesBigger />}
				</>
			) : firstArticle ? (
				<>
					<div className={styles.section}>
						<TileBanner article={firstArticle} />
					</div>
					{restArticles.length > 0 && <Tiles items={restArticles} isFirstArticlesBigger />}
				</>
			) : null}

			{query.hasNextPage && (
				<div className={styles.buttonWrapper}>
					<Button type="button" variant="secondary" onClick={onClick} disabled={query.isFetchingNextPage}>
						{translate('button.loadMore')}
						{query.isFetchingNextPage && <LoadingSpinner />}
					</Button>
				</div>
			)}
		</div>
	)
}
