// Reexport all svg icons
export { default as arrowLifeCycle } from './arrowLifeCycle.svg'
export { default as arrowLink } from './arrowLink.svg'
export { default as arrowUp } from './arrowUp.svg'
export { default as at } from './at.svg'
export { default as chevron } from './chevron.svg'
export { default as cross } from './cross.svg'
export { default as dashedArrow } from './dashedArrow.svg'
export { default as graph } from './graph.svg'
export { default as house } from './house.svg'
export { default as instagram } from './instagram.svg'
export { default as linkedn } from './linkedin.svg'
export { default as message } from './message.svg'
export { default as pencilCircle } from './pencilCircle.svg'
export { default as pencilCircle2 } from './pencilCircle2.svg'
export { default as pencilUnderline } from './pencilUnderline.svg'
export { default as pin } from './pin.svg'
export { default as plusCircle } from './plusCircle.svg'
export { default as point } from './point.svg'
export { default as pointWithNoSpacing } from './pointWithNoSpacing.svg'
export { default as rainbowWave } from './rainbowWave.svg'
export { default as sandwich } from './sandwich.svg'
export { default as telephone } from './telephone.svg'
export { default as twitter } from './twitter.svg'
export { default as wavingPerson } from './wavingPerson.svg'
export { default as world } from './world.svg'
export { default as download } from './download.svg'
export { default as search } from './search.svg'
export { default as check } from './check.svg'
export { default as calendar } from './calendar.svg'
export { default as downloads } from './downloads.svg'
export { default as videos } from './videos.svg'
export { default as hat } from './hat.svg'
export { default as paperPlane } from './paperPlane.svg'
export { default as rotatingArrow } from './rotatingArrow.svg'
export { default as hot } from './hot.svg'
export { default as headset } from './headset.svg'
export { default as guide } from './guide.svg'
export { default as lock } from './lock.svg'
export { default as print } from './print.svg'
export { default as facebook } from './facebook.svg'
export { default as linkedin } from './linkedin.svg'
export { default as x } from './x.svg'
