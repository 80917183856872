import 'hiding-header/dist/style.css'
import Image from 'next/image'
import { useMemo, type FunctionComponent } from 'react'
import type { ArticleTileResult } from '../data/ArticleFragment'
import { ellipsedText } from '../utilities/ellipsedTextHelper'
import { formatDate } from '../utilities/formatDate'
import { getReadingTime } from '../utilities/getReadingTime'
import styles from './FrontMagazineBanner.module.sass'
import { LinkChildren } from './LinkChildren'
import { useTranslate } from './contexts/TranslationsContextProvider'

type FrontMagazineBannerProps = {
	article: ArticleTileResult
}
export const FrontMagazineBanner: FunctionComponent<FrontMagazineBannerProps> = ({ article }) => {
	const translation = useTranslate()

	const LAST_CHARACTER_VISIBLE = 180
	const ellipsedLead = useMemo(() => {
		if (article.lead && article.lead.length > LAST_CHARACTER_VISIBLE) {
			return ellipsedText(article.lead, LAST_CHARACTER_VISIBLE)
		}
		return article.lead
	}, [article.lead])

	return (
		<div className={styles.wrapper}>
			<LinkChildren key={article.id} type="internal" link={article.link} className={styles.link}>
				<div className={styles.item}>
					<div className={styles.imageWrapper}>
						{article.tilePhoto && (
							<Image
								className={styles.image}
								src={article.tilePhoto.url}
								fill
								alt={article.tilePhoto.alt ?? ''}
								priority
								quality={75}
							/>
						)}
					</div>
					<div className={styles.content}>
						<div className={styles.contentTop}>
							{article.publishedAt && <div className={styles.publishedAt}>{formatDate('cs', article.publishedAt)}</div>}
							{article.readingTime && article.readingTime > 0 && (
								<div className={styles.readingTime}>
									{article.readingTime} {translation(`${getReadingTime(article.readingTime)}`)}
								</div>
							)}
						</div>
						{article.title && <h3 className={styles.title}>{article.title}</h3>}
						{article.lead && <p className={styles.contentLead}>{ellipsedLead}</p>}
					</div>
				</div>
			</LinkChildren>
		</div>
	)
}
