import type { FunctionComponent } from 'react'
import type { FileResult } from '../data/FileFragment'
import styles from './FileGrid.module.sass'
import { FileTile } from './FileTile'

export type FileGridProps = {
	files: FileResult[]
}

export const FileGrid: FunctionComponent<FileGridProps> = ({ files }) => {
	return (
		<div className={styles.wrapper}>
			{files.map((file) => (
				<FileTile key={file.url} file={file} />
			))}
		</div>
	)
}
