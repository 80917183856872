import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { ArticleTileResult } from '../data/ArticleFragment'
import { formatDate } from '../utilities/formatDate'
import { getReadingTime } from '../utilities/getReadingTime'
import { LinkChildren } from './LinkChildren'
import styles from './Tiles.module.sass'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type TilesProps = {
	title?: string
	items: ArticleTileResult[]
	isFirstArticlesBigger?: boolean
}

export const Tiles: FunctionComponent<TilesProps> = ({ title, items, isFirstArticlesBigger = false }) => {
	const translation = useTranslate()
	return (
		<div className={styles.wrapper}>
			{title && <h2 className={styles.title}>{title}</h2>}
			<div className={clsx(styles.items, isFirstArticlesBigger && styles.isFirstArticlesBigger)}>
				{items.map(
					(tile) =>
						tile && (
							<LinkChildren key={tile.id} type="internal" link={tile.link} className={styles.link}>
								<div className={styles.item}>
									<div className={styles.imageWrapper}>
										{tile.productOwner?.image ? (
											<Image
												className={styles.image}
												src={tile.productOwner?.image?.url ?? ''}
												fill
												alt={tile.productOwner?.image?.alt ?? ''}
												sizes="(min-width: 30rem) 30vw, 100vw)"
											/>
										) : tile.tilePhoto ? (
											<Image
												className={styles.image}
												src={tile.tilePhoto.url}
												fill
												alt={tile.tilePhoto.alt ?? ''}
												sizes="(min-width: 30rem) 30vw, 100vw)"
											/>
										) : null}
									</div>
									<div className={styles.content}>
										<div className={styles.contentTop}>
											<div className={styles.publishedAt}>{tile.publishedAt && formatDate('cs', tile.publishedAt)}</div>
											{tile.readingTime && tile.readingTime > 0 && (
												<div className={styles.readingTime}>
													{tile.readingTime} {translation(`${getReadingTime(tile.readingTime)}`)}
												</div>
											)}
										</div>
										{tile.title && <h5 className={styles.contentTitle}>{tile.title}</h5>}
									</div>
								</div>
							</LinkChildren>
						),
				)}
			</div>
		</div>
	)
}
