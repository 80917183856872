import type { FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { AsideMenu } from './AsideMenu'
import { Container } from './Container'
import styles from './WikiPage.module.sass'
import { ItemListWiki } from './ItemListWiki'

export type WikiPageWithCategoryProps = NonNullable<PageProps['page']['wikiPageWithCategory']>

export const WikiPageWithCategory: FunctionComponent<WikiPageWithCategoryProps> = ({
	page,
	category,
	menuCategories,
	wikiArticlePagination,
}) => {
	return (
		<Container size="normal">
			<div className={styles.wrapper}>
				<AsideMenu page={page} categories={menuCategories} activeCategoryId={category?.id} />
				<Container size="small" disableGutters>
					<ItemListWiki
						category={category}
						listCategories={menuCategories}
						wikiArticlePagination={wikiArticlePagination}
					/>
				</Container>
			</div>
		</Container>
	)
}
