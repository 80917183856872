import type { FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { AsideMenu } from './AsideMenu'
import { Container } from './Container'
import { ItemListProductNews } from './ItemListProductNews'
import styles from './ProductNewsPage.module.sass'

export type ProductNewsPageWithCategoryProps = NonNullable<PageProps['page']['productNewsPageWithCategory']>

export const ProductNewsPageWithCategory: FunctionComponent<ProductNewsPageWithCategoryProps> = ({
	page,
	category,
	menuCategories,
	productNewsArticlePagination,
}) => {
	return (
		<Container size="normal">
			<div className={styles.wrapper}>
				<AsideMenu page={page} categories={menuCategories} activeCategoryId={category?.id} />
				<Container size="small" disableGutters>
					<ItemListProductNews
						category={category}
						listCategories={menuCategories}
						productNewsArticlesPagination={productNewsArticlePagination}
					/>
				</Container>
			</div>
		</Container>
	)
}
