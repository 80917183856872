import { RichTextRenderer } from '@contember/react-client'
import Link from 'next/link'
import type { CSSProperties, FunctionComponent } from 'react'
import type { LinkResult } from '../data/LinkFragment'
import { GREY_BORDER_COLOR } from './ArticlePromoBox'
import styles from './ComposedBlock.module.sass'

type ComposedBlockProps = {
	primaryBlock?: { jsonContent?: string | undefined } | undefined
	secondaryBlock?: {
		jsonContent?: string | undefined
		primaryText?: string | undefined
		link?: LinkResult | undefined
		color?: string | undefined
	}
}

export const ComposedBlock: FunctionComponent<ComposedBlockProps> = ({ primaryBlock, secondaryBlock }) => {
	return (
		<div className={styles.wrapper}>
			{primaryBlock?.jsonContent && (
				<div className={styles.primaryWrapper}>
					<RichTextRenderer source={primaryBlock.jsonContent} />
				</div>
			)}
			<div
				className={styles.secondaryWrapper}
				style={
					{
						'--secondary-block-color': secondaryBlock?.color ?? GREY_BORDER_COLOR,
					} as CSSProperties // Custom properties not supported workaround
				}
			>
				<div className={styles.title}>{secondaryBlock?.primaryText}</div>
				{secondaryBlock?.jsonContent && <RichTextRenderer source={secondaryBlock.jsonContent} />}
				{secondaryBlock?.link && (
					<Link href={secondaryBlock.link.externalLink ?? secondaryBlock.link.internalLink?.url ?? ''}>
						{secondaryBlock?.link.externalLink ?? secondaryBlock.link.internalLink?.url}
					</Link>
				)}
			</div>
		</div>
	)
}
