import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import Image from 'next/image'
import type { CSSProperties, FunctionComponent } from 'react'
import type { QuoteType } from '../../generated/contember/zeus'
import type { ImageResult } from '../data/ImageFragment'
import { GREY_BORDER_COLOR } from './ArticlePromoBox'
import { Button } from './Button'
import styles from './Quote.module.sass'
import { useTranslate } from './contexts/TranslationsContextProvider'

type QuoteProps = {
	color?: string
	image?: ImageResult
	type?: QuoteType
	quoteText?: string
	author?: string
	shareable?: boolean
	role?: string
	jsonContent: string
}

export const Quote: FunctionComponent<QuoteProps> = ({ color, image, type, author, jsonContent, role, shareable }) => {
	const translate = useTranslate()
	return (
		<div
			className={clsx(
				styles.wrapper,
				type === 'box' && styles.wrapperBox,
				// type === 'primary' && styles.wrapperPrimary,
				// type === 'secondary' && styles.wrapperSecondary,
			)}
			style={
				{
					'--quote-color': color ?? GREY_BORDER_COLOR,
				} as CSSProperties // Custom properties not supported workaround
			}
		>
			<div className={styles.in}>
				<div className={styles.text}>
					<RichTextRenderer source={jsonContent} />
				</div>
				{author && (
					<div className={styles.meta}>
						{image && (
							<div className={styles.imageWrapper}>
								<Image src={image.url} alt={image.alt ?? ''} fill />
							</div>
						)}
						<div className={styles.metaContent}>
							<div className={styles.author}>{author}</div>
							<div className={styles.role}>{role}</div>
						</div>
					</div>
				)}

				{shareable && (
					<Button type="link" href={'www.todo.cz'} size="small">
						{translate('shareable.button.x')}
					</Button>
				)}
			</div>
		</div>
	)
}
