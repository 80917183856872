/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState, type FunctionComponent } from 'react'
import { api } from '../utilities/api'
import { Button } from './Button'
import { FileGrid } from './FileGrid'
import styles from './FileList.module.sass'
import { Icon } from './Icon'
import { Input } from './Input'
import { LoaderSpinner } from './LoaderSpinner'
import { LoadingSpinner } from './LoadingSpinner'
import { Select } from './Select'
import { useSite } from './contexts/SiteContextProvider'
import { useTranslate } from './contexts/TranslationsContextProvider'
import { NoFileBanner } from './NoFileBanner'

export type FileListProps = {
	filePagination: any
}

export const FileList: FunctionComponent<FileListProps> = ({ filePagination }) => {
	const { siteCode } = useSite()
	const translate = useTranslate()
	const [orderBy, setOrderBy] = useState('')
	const [searchValue, setSearchValue] = useState('')

	const query = api.paginateFile.useInfiniteQuery(
		{ siteCode },
		{
			getNextPageParam: (lastPage) => lastPage.nextCursor,
			initialCursor: filePagination.cursor,
			initialData: {
				pageParams: [filePagination.cursor],
				pages: [filePagination],
			},
		},
	)

	const filteredFiles = api.listFile.useQuery({ siteCode, orderBy, searchValue })

	const onClick = useCallback(() => {
		query.fetchNextPage()
	}, [query])

	const files =
		searchValue || orderBy ? filteredFiles.data?.listFile : query.data?.pages?.flatMap((page) => page.data.items)

	const selectOptions = [
		{ value: 'date', label: translate('filesPage.select.byDate') },
		{ value: 'type', label: translate('filesPage.select.byType') },
		{ value: 'title', label: translate('filesPage.select.byTitle') },
		{ value: 'size', label: translate('filesPage.select.bySize') },
	]

	const translation = useTranslate()
	return (
		<div className={styles.wrapper}>
			<div className={styles.inputGroup}>
				<div className={styles.searchWrapper}>
					<Input
						hasIcon
						placeholder={translation('searchPage.placeholder.email')}
						onChange={(event) => {
							setSearchValue(event.target.value)
						}}
					/>
					<div className={styles.searchIcon}>
						<Icon name="search" />
					</div>
				</div>
				<div className={styles.selectWrapper}>
					<Select options={selectOptions} onChange={(e) => setOrderBy(e.target.value)} />
				</div>
			</div>
			{(orderBy || searchValue) && filteredFiles.isLoading && (
				<div className={styles.loaderWrapper}>
					<LoaderSpinner />
				</div>
			)}
			{files && !!files.length && <FileGrid files={files} />}
			{files && files.length === 0 && <NoFileBanner />}
			{(!orderBy || !searchValue) && files && files.length > 0 && query.hasNextPage && (
				<div className={styles.buttonWrapper}>
					<Button type="button" variant="secondary" onClick={onClick} disabled={query.isFetchingNextPage}>
						{translate('button.loadMore')}
						{query.isFetchingNextPage && <LoadingSpinner />}
					</Button>
				</div>
			)}
		</div>
	)
}
