import type { FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { AsideMenu } from './AsideMenu'
import { Container } from './Container'
import styles from './PodcastPage.module.sass'
import { ItemListPodcast } from './ItemListPodcast'

export type PodcastPageWithCategoryProps = NonNullable<PageProps['page']['podcastPageWithCategory']>

export const PodcastPageWithCategory: FunctionComponent<PodcastPageWithCategoryProps> = ({
	heading,
	page,
	category,
	menuCategories,
	podcastArticlePagination,
}) => {
	return (
		<Container size="normal">
			<div className={styles.wrapper}>
				<AsideMenu page={page} heading={heading} categories={menuCategories} activeCategoryId={category?.id} />
				<Container size="small" disableGutters>
					<ItemListPodcast
						category={category}
						listCategories={menuCategories}
						podcastArticlePagination={podcastArticlePagination}
					/>
				</Container>
			</div>
		</Container>
	)
}
