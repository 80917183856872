import clsx from 'clsx'
import type { CSSProperties, FunctionComponent } from 'react'
import type { MailingListResult } from '../data/MailingListFragment'
import { GREY_BORDER_COLOR } from './ArticlePromoBox'
import { MailingListForm } from './MailingListForm'
import styles from './SubscribeBox.module.sass'

export type SubscribeBoxProps = {
	color?: string
	title?: string
	successNote?: string
	mailingList?: MailingListResult
}

export const SubscribeBox: FunctionComponent<SubscribeBoxProps> = ({ color, title, successNote, mailingList }) => {
	return (
		<div
			className={clsx(styles.wrapper, color && styles.borderColor)}
			style={
				{
					'--subscribe-box-color': color ?? GREY_BORDER_COLOR,
				} as CSSProperties // Custom properties not supported workaround
			}
		>
			<div className={styles.title}>{title}</div>
			<MailingListForm list={mailingList} successNote={successNote} />
		</div>
	)
}
