import type { ContentResult } from '../data/ContentFragment'

export const getNonHiddenContentBlocks = (content?: ContentResult): ContentResult => {
	if (!content) {
		return {
			id: '',
			blocks: [],
		}
	}

	const contentLockBlockIndex = content.blocks.findIndex((block) =>
		block.references.find((reference) => reference.type === 'contentLock'),
	)

	const nonHiddenBlocks = [...content.blocks].splice(0, contentLockBlockIndex + 1)

	if (contentLockBlockIndex > -1) {
		return {
			...content,
			blocks: nonHiddenBlocks,
		}
	}

	return content
}

export const excludeContentLockBlocks = (content?: ContentResult): ContentResult => {
	if (!content) {
		return {
			id: '',
			blocks: [],
		}
	}

	const contentLockBlockIndex = content.blocks.findIndex((block) =>
		block.references.find((reference) => reference.type === 'contentLock'),
	)

	const blocks = [...content.blocks]

	blocks.splice(contentLockBlockIndex, 1)

	if (contentLockBlockIndex > -1) {
		return {
			...content,
			blocks: blocks,
		}
	}

	return content
}
