import clsx from 'clsx'
import 'hiding-header/dist/style.css'
import type { FunctionComponent } from 'react'
import type { ArticleTileResult } from '../data/ArticleFragment'
import { FrontArticleBanner, type FrontArticleBannerProps } from './FrontArticleBanner'
import styles from './FrontArticles.module.sass'
import { FrontButton } from './FrontButton'
import { FrontHeader } from './FrontHeader'
import type { FrontPageProps } from './FrontPage'
import type { IconName } from './Icon'

type FrontArticlesProps = Pick<FrontArticleBannerProps, 'mobileLayout'> & {
	articles: ArticleTileResult[]
	buttonLink?: FrontPageProps['productNewsPageUrl']
	buttonLabel?: string
	title: string
	iconName?: IconName
	isHorizontal?: boolean
	objectFit?: 'cover' | 'contain'
}
export const FrontArticles: FunctionComponent<FrontArticlesProps> = ({
	articles,
	buttonLink,
	buttonLabel,
	isHorizontal,
	title,
	iconName,
	objectFit = 'contain',
	mobileLayout,
}) => {
	return (
		<div className={clsx(styles.wrapper, isHorizontal && styles.wrapperHorizontal)}>
			<FrontHeader title={title} iconName={iconName} />
			<div className={clsx(styles.content, isHorizontal && styles.contentHorizontal)}>
				{articles.map((article) => (
					<FrontArticleBanner article={article} key={article.id} objectFit={objectFit} mobileLayout={mobileLayout} />
				))}
			</div>
			{buttonLink && buttonLabel && <FrontButton buttonLabel={buttonLabel} buttonLink={buttonLink} />}
		</div>
	)
}
