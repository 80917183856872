import clsx from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'
import styles from './SubtitleText.module.sass'

const subtitleTextType = {
	subtitle1: 'subtitle1',
	subtitle2: 'subtitle2',
} as const
type SubtitleTextTypeProps = (typeof subtitleTextType)[keyof typeof subtitleTextType]

export type SubtitleTextElement = {
	type: 'subtitleText'
	suchThat: {
		subtitleType: SubtitleTextTypeProps
	}
}

export type SubtitleTextProps = {
	subtitleType: SubtitleTextTypeProps
	children: ReactNode
}

export const SubtitleText: FunctionComponent<SubtitleTextProps> = ({ subtitleType, children }) => {
	return <span className={clsx(styles.wrapper, styles[`is_${subtitleType}`])}>{children}</span>
}
