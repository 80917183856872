import 'hiding-header/dist/style.css'
import type { FunctionComponent } from 'react'
import { Button } from './Button'
import styles from './FrontButton.module.sass'

type FrontButtonProps = {
	buttonLink: string
	buttonLabel: string
}
export const FrontButton: FunctionComponent<FrontButtonProps> = ({ buttonLink, buttonLabel }) => {
	return (
		<div className={styles.buttonWrapper}>
			<Button type="link" href={buttonLink} variant="secondary" size="small">
				{buttonLabel}
			</Button>
		</div>
	)
}
