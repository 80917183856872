import clsx from 'clsx'
import 'hiding-header/dist/style.css'
import type { FunctionComponent } from 'react'
import type { FileResult } from '../data/FileFragment'
import { FrontButton } from './FrontButton'
import { FrontFile } from './FrontFile'
import styles from './FrontFiles.module.sass'
import { FrontHeader } from './FrontHeader'
import type { FrontPageProps } from './FrontPage'
import type { IconName } from './Icon'

type FrontFilesProps = {
	files: FileResult[]
	buttonLink: FrontPageProps['filesPageUrl']
	buttonLabel?: string
	title: string
	iconName?: IconName
	isHorizontal?: boolean
	noEvents?: boolean
}
export const FrontFiles: FunctionComponent<FrontFilesProps> = ({
	files,
	buttonLink,
	buttonLabel,
	isHorizontal,
	title,
	iconName,
	noEvents = true,
}) => {
	return (
		<div className={styles.wrapper}>
			<FrontHeader title={title} iconName={iconName} />
			<div className={clsx(styles.content, isHorizontal && styles.contentHorizontal, noEvents && styles.contentWide)}>
				{files && files.length > 0 && files.map((file) => <FrontFile file={file} key={file.id} />)}
			</div>
			{buttonLink && buttonLabel && <FrontButton buttonLabel={buttonLabel} buttonLink={buttonLink} />}
		</div>
	)
}
