import type { FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { AsideMenu } from './AsideMenu'
import { Container } from './Container'
import { ItemListPodcast } from './ItemListPodcast'
import styles from './PodcastPage.module.sass'

export type PodcastPageProps = NonNullable<PageProps['page']['podcastPage']>

export const PodcastPage: FunctionComponent<PodcastPageProps> = ({
	heading,
	menuCategories,
	podcastArticlePagination,
}) => {
	return (
		<Container size="normal">
			<div className={styles.wrapper}>
				<AsideMenu heading={heading} categories={menuCategories} />
				<Container size="small" disableGutters>
					<ItemListPodcast podcastArticlePagination={podcastArticlePagination} />
				</Container>
			</div>
		</Container>
	)
}
