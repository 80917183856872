import clsx from 'clsx'
import Link from 'next/link'
import { useState, type FunctionComponent } from 'react'
import type { SiteResult } from '../data/SiteFragment'
import { WEB_URL } from '../utilities/webUrl'
import { Icon } from './Icon'
import styles from './SitePicker.module.sass'
import { useSite } from './contexts/SiteContextProvider'

export type SitePickerProps = {
	site: string | undefined
	otherSites: SiteResult[]
}

export const SitePicker: FunctionComponent<SitePickerProps> = ({ site, otherSites }) => {
	const [siteMenuVisible, setSiteMenuVisible] = useState(false)

	const { siteCode } = useSite()

	return (
		<div className={styles.wrapper} onClick={() => setSiteMenuVisible(!siteMenuVisible)}>
			<div className={styles.iconWrapper}>
				<Icon name="world" />
			</div>
			<div className={styles.site}>{site?.split('(')[0].trim() ?? siteCode}</div>
			<div className={clsx(siteMenuVisible && styles.siteMenuIsVisible, styles.siteMenu)}>
				{otherSites.map((site) => (
					<Link key={site.id} href={`${WEB_URL}/${site.code}`}>
						<div className={styles.siteMenuItem}>{site.title.split('(')[0].trim()}</div>
					</Link>
				))}
			</div>
		</div>
	)
}
