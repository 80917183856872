import clsx from 'clsx'
import 'hiding-header/dist/style.css'
import { useEffect, useRef, useState, type FunctionComponent } from 'react'
import type { WebinarCategoryResult } from '../data/WebinarCategoryFragment'
import type { FilterQuery } from '../utilities/FilterQuery'
import { CheckboxGroup } from './CheckboxGroup'
import { Icon } from './Icon'
import styles from './WebinarSelect.module.sass'
import { useTranslate } from './contexts/TranslationsContextProvider'

type WebinarSelectProps = {
	items: WebinarCategoryResult[]
	initialQuery: FilterQuery
	label: string
	categories: string[]
	url: string | undefined
}
export const WebinarSelect: FunctionComponent<WebinarSelectProps> = ({
	label,
	url,
	items,
	initialQuery,
	categories,
}) => {
	const translation = useTranslate()

	const [isGroupVisible, setIsGroupVisible] = useState(false)

	const [clickedOutside, setClickedOutside] = useState(false)
	const myRef = useRef<HTMLDivElement>(null)

	const handleClickOutside = (e) => {
		if (myRef.current && !myRef.current.contains(e.target)) {
			setClickedOutside(true)
		}
	}

	const handleClickInside = () => setClickedOutside(false)

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => document.removeEventListener('mousedown', handleClickOutside)
	})

	const firstTwo = categories.slice(0, 2)
	return (
		<div className={styles.wrapper} ref={myRef} onClick={handleClickInside}>
			<div className={styles.select} onClick={() => setIsGroupVisible(!isGroupVisible)}>
				<div className={styles.selectItemsWrapper}>
					{categories.length === 0
						? translation('webinarPage.select.allCategories')
						: firstTwo.map((cat) => (
								<>
									<span className={styles.separatedByComma} key={cat}>
										{cat}
									</span>
								</>
						  ))}
				</div>
				{categories.length > 2 && <span>...</span>}
				<div className={clsx(styles.icon, !clickedOutside && isGroupVisible && styles.iconRotate)}>
					<div className={styles.iconIn}>
						<Icon name="chevron" />
					</div>
				</div>
			</div>
			<div className={clsx(styles.list, !clickedOutside && isGroupVisible && styles.listIsVisible)}>
				<CheckboxGroup
					url={url}
					items={items}
					label={label}
					initialQuery={initialQuery}
					isVertical
					closeSelect={() => setIsGroupVisible(false)}
				/>
			</div>
		</div>
	)
}
