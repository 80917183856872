import { useCallback, useState, type FormEventHandler, type FunctionComponent } from 'react'
import { toast } from 'react-toastify'
import { useMirrorLoading } from 'shared-loading-indicator'
import type { FileResult } from '../data/FileFragment'
import type { FormResult } from '../data/FormFragment'
import { api } from '../utilities/api'
import { getFileType } from '../utilities/getFileType'
import { getSizeInMb } from '../utilities/getSizeInMb'
import { Button } from './Button'
import styles from './ContentFile.module.sass'
import { Icon } from './Icon'
import { Input } from './Input'
import { Modal } from './Modal'
import { useSite } from './contexts/SiteContextProvider'
import { useTranslate } from './contexts/TranslationsContextProvider'

type ContentFileProps = {
	title: string | undefined
	file: FileResult
	form?: FormResult
}

export const ContentFile: FunctionComponent<ContentFileProps> = ({ file, title, form }) => {
	const [modalOpen, setModalOpen] = useState(false)
	const [isUnlocked, setIsUnlocked] = useState(false)

	const [email, setEmail] = useState<string | null>(null)
	const [phone, setPhone] = useState<string | null>(null)
	const [name, setName] = useState<string | null>(null)
	const [jobTitle, setJobTitle] = useState<string | null>(null)

	const close = () => {
		setModalOpen(false)
		clearForm()
	}

	const { siteCode } = useSite()

	const mutation = api.contact.send.useMutation({
		onSuccess: () => {
			close()
			setIsUnlocked(true)
		},
		onError: () => {
			toast('Něco se při odesílání pokazilo. Zkuste to znovu prosím později.', {
				type: 'error',
			})
		},
	})
	const isPending = mutation.isPending
	useMirrorLoading(isPending)

	const clearForm = useCallback(() => {
		setEmail('')
		setJobTitle('')
		setName('')
		setPhone('')
	}, [])

	const isSuccess = mutation.isSuccess

	const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
		(event) => {
			event.preventDefault()

			mutation.mutate({
				siteCode,
				email: email ?? '',
				phone: phone ?? '',
				name: name ?? '',
				jobTitle: jobTitle ?? '',
			})
			clearForm()
			setIsUnlocked(true)
		},
		[mutation, siteCode, email, phone, name, jobTitle, clearForm],
	)

	const type = getFileType(file.type) ? `${getFileType(file.type)},` : ''

	const translate = useTranslate()

	const fileButtonTitle = `${translate('file.download.button.link')} ${type} ${getSizeInMb(file.size)}`

	return (
		<div className={styles.wrapper}>
			<div className={styles.left}>
				{title && <div className={styles.perex}>{title}</div>}

				{file && (
					<>
						<div className={styles.title}>{file.title}</div>

						{form && !isUnlocked ? (
							<button className={styles.button} onClick={() => setModalOpen(true)}>
								{fileButtonTitle}
							</button>
						) : (
							<>
								<a key={file.url} href={file.url} download={file.name} target="_blank" className={styles.link}>
									{fileButtonTitle}
								</a>
								{form && isUnlocked && isSuccess && <span>{translate('file.readyToDownload')}</span>}
							</>
						)}
					</>
				)}
			</div>
			<div className={styles.iconWrapper}>
				<Icon name="download" />
			</div>
			{modalOpen && (
				<Modal close={() => setModalOpen(false)} isOpen={modalOpen}>
					<form className={styles.form} action="" method="POST" onSubmit={onSubmit}>
						<fieldset className={styles.formIn}>
							<div className={styles.formTitle}>{form?.title}</div>
							{form?.fields.map((field) => (
								<Input
									key={field.label}
									type={field.type === 'email' ? 'email' : 'text'}
									onChange={(e) =>
										field.type === 'email'
											? setEmail(e.target.value)
											: field.type === 'phone'
											? setPhone(e.target.value)
											: field.type === 'name'
											? setName(e.target.value)
											: setJobTitle(e.target.value)
									}
									placeholder={field.label}
									formLocated
								/>
							))}
							<div className={styles.buttonWrapper}>
								<Button type="submit" size="small">
									{form?.submit}
								</Button>
							</div>
							<div className={styles.buttonWrapper}>
								<Button type="button" onClick={close} variant="text" size="small">
									{form?.close}
								</Button>
							</div>
						</fieldset>
					</form>
				</Modal>
			)}
		</div>
	)
}
