import clsx from 'clsx'
import 'hiding-header/dist/style.css'
import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { isDefined } from '../utilities/isDefined'
import type { HeaderProps } from './Header'
import styles from './HeaderNestedLinks.module.sass'

export type HeaderNestedLinksProps = {
	items: NonNullable<NonNullable<HeaderProps['links'][number]>['nestedLinks']>['items']
	onLinkClick?: () => void
}

export const HeaderNestedLinks: FunctionComponent<HeaderNestedLinksProps> = ({ items, onLinkClick }) => {
	const isProducts = items.length > 4
	return (
		<div className={clsx(styles.wrapper, isProducts && styles.productsWrapper)}>
			<div className={styles.in}>
				<div className={styles.box}>
					<div className={styles.list}>
						{items
							.map((item) => {
								if (!item.link) {
									return null
								}
								return (
									<Link
										className={styles.item}
										key={item.id}
										href={contemberLinkToHref(item.link)}
										onClick={onLinkClick}
									>
										<div className={styles.image}>
											{item.image && (
												<Image
													alt={item.image.alt ?? ''}
													src={item.image.url}
													width={item.image.width ?? 65}
													height={item.image.height ?? 80}
													sizes={`80px`}
												/>
											)}
										</div>
										<div className={styles.itemContent}>
											<span className={styles.title}>{item.link.title}</span>
											{item.description && <span className={styles.description}>{item.description}</span>}
										</div>
									</Link>
								)
							})
							.filter(isDefined)}
					</div>
				</div>
			</div>
		</div>
	)
}
