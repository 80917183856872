import type { FunctionComponent } from 'react'
import type { ArticleTileResult } from '../data/ArticleFragment'
import { formatDate } from '../utilities/formatDate'
import { getReadingTime } from '../utilities/getReadingTime'
import { LinkChildren } from './LinkChildren'
import styles from './RelatedArticle.module.sass'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type RelatedArticleProps = {
	article: ArticleTileResult
}

export const RelatedArticle: FunctionComponent<RelatedArticleProps> = ({ article }) => {
	const translation = useTranslate()
	return (
		<div className={styles.wrapper}>
			<LinkChildren link={article.link} type="internal">
				{article.title && <h3 className={styles.title}>{article.title}</h3>}
				<div className={styles.description}>
					{article.publishedAt && <div className={styles.date}>{formatDate('cs', article.publishedAt)}</div>}
					{article.readingTime && article.readingTime > 0 && (
						<div className={styles.date}>
							{article.readingTime} {translation(`${getReadingTime(article.readingTime)}`)}
						</div>
					)}
				</div>
			</LinkChildren>
		</div>
	)
}
