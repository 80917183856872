import { useRouter } from 'next/router'
import { useCallback, type FunctionComponent } from 'react'
import { Checkbox } from './Checkbox'
import style from './CheckboxGroup.module.sass'
import type { FilterQuery } from '../utilities/FilterQuery'
import type { WebinarLabelResult } from '../data/WebinarLabelFragment'
import clsx from 'clsx'
import type { WebinarCategoryResult } from '../data/WebinarCategoryFragment'
import { useTranslate } from './contexts/TranslationsContextProvider'
import { Icon } from './Icon'
import { useSite } from './contexts/SiteContextProvider'

type CheckboxGroupProps = {
	items: WebinarLabelResult[] | WebinarCategoryResult[]
	isDeleteAllVisible?: boolean
	defaultChecked?: string[]
	label: string
	initialQuery: FilterQuery
	isVertical?: boolean
	url: string | undefined
	closeSelect?: () => void
}

export const CheckboxGroup: FunctionComponent<CheckboxGroupProps> = ({
	items,
	defaultChecked,
	label,
	initialQuery,
	isVertical,
	closeSelect,
}) => {
	const router = useRouter()
	const { query } = useRouter()
	const { siteCode } = useSite()

	const handleChange = useCallback(
		(key: string, id: string | number) => {
			const itemQueryArray = initialQuery[key as keyof FilterQuery] ?? []

			const stringId = String(id)
			const isChecked = itemQueryArray?.includes(stringId)

			if (!isChecked) {
				itemQueryArray.push(stringId)
			} else {
				const index = itemQueryArray.indexOf(stringId)
				if (index !== -1) {
					itemQueryArray.splice(index, 1)
				}
			}

			if (Array.isArray(router.query.path) && router.query.path?.length === 1) {
				router.query.path.unshift(siteCode)
			}

			if (router.query.path && router.query.path?.length === 2) {
				router.push({ pathname: router.pathname, query: { ...query, [key]: itemQueryArray } }, undefined, {
					scroll: false,
				})
			}
		},
		[initialQuery, query, router, siteCode],
	)
	const translation = useTranslate()

	const deleteQuery = (label: string) => {
		router.replace({ pathname: router.pathname, query: { ...query, [label]: [], category: [] } }, undefined, {
			scroll: false,
			locale: siteCode,
		})
	}
	return (
		<div className={clsx(style.root, isVertical && style.rootVertical)}>
			{items.map((item) => (
				<Checkbox
					key={item.id}
					label={item.title[0].toUpperCase() + item.title.slice(1)}
					onChange={() => {
						handleChange(label, String(item.title))
					}}
					checked={
						defaultChecked
							? defaultChecked.includes(item.id)
							: (initialQuery[label as keyof FilterQuery] &&
									(initialQuery[label as keyof FilterQuery] as string[]).includes(String(item.title))) ??
							  false
					}
				/>
			))}
			{((initialQuery.label && initialQuery.label.length > 0) ||
				(initialQuery.category && initialQuery.category.length > 0)) && (
				<div className={clsx(style.buttonWrapper, isVertical && style.buttonWrapperLeft)}>
					<button
						type="button"
						onClick={() => {
							deleteQuery(label as keyof FilterQuery)
							closeSelect && closeSelect()
						}}
						className={style.button}
					>
						<div className={clsx(style.icon, style.buttonIcon)}>
							<Icon name="cross" />
						</div>
						{translation('webinarPage.select.clearFilter')}
					</button>
				</div>
			)}
		</div>
	)
}
