import { RichTextRenderer } from '@contember/react-client'
import 'hiding-header/dist/style.css'
import { type FormEventHandler, type FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useMirrorLoading } from 'shared-loading-indicator'
import type { MailingListResult } from '../data/MailingListFragment'
import { api } from '../utilities/api'
import { useIsInView } from '../utilities/useIsInView'
import { Button } from './Button'
import { Input } from './Input'
import styles from './MailingListForm.module.sass'
import { useSite } from './contexts/SiteContextProvider'
import { useTranslate } from './contexts/TranslationsContextProvider'

type MailingListFormProps = {
	list: MailingListResult | undefined
	successNote?: string
}
export const MailingListForm: FunctionComponent<MailingListFormProps> = ({ list, successNote }) => {
	const translation = useTranslate()
	const { siteCode } = useSite()
	const form = useRef<HTMLFormElement>(null)

	const [BrevoData] = useState(list?.brevoData)
	const [email, setEmail] = useState('')

	const mutation = api.subscribeOnMailingList.send.useMutation({
		onError: () => {
			toast('Něco se při odesílání pokazilo. Zkuste to znovu prosím později.', {
				type: 'error',
			})
		},
	})
	const isPending = mutation.isPending
	useMirrorLoading(isPending)

	const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
		(event) => {
			event.preventDefault()
			if (list && BrevoData) {
				mutation.mutate({
					siteCode,
					mailingListId: list.id,
					brevoListId: BrevoData.listId,
					email,
				})
			} else {
				console.error('Sendinblue data not found.')
				toast('Něco se při odesílání pokazilo. Zkuste to znovu prosím později.', {
					type: 'error',
				})
			}
		},
		[BrevoData, email, list, mutation, siteCode],
	)

	const sendDataLayers = useCallback(
		(isSuccess: boolean) => {
			window.dataLayer = window.dataLayer || []

			if (isSuccess) {
				window.dataLayer.push({
					event: 'form_newsletter_subscribe',
					hit: {
						event_type: 'generic_event_content',
						content_type: 'form',
						content_id:
							mutation.data?.contemberStatus && 'id' in mutation.data.contemberStatus
								? mutation.data?.contemberStatus.id
								: '',
						user_email: email,
					},
					_clear: true,
				})
			} else {
				window.dataLayer.push({
					event: 'form_newsletter_error',
					hit: {
						event_type: 'generic_event_content',
						content_type: 'form',
						content_text: mutation.data?.errorResponse?.message,
					},
					_clear: true,
				})
			}
		},
		[email, mutation.data?.contemberStatus, mutation.data?.errorResponse?.message],
	)

	// success/error form handling
	useEffect(() => {
		if (mutation.status === 'error' || mutation.status === 'success') {
			mutation.data?.status === 'success'
				? sendDataLayers(true)
				: mutation.data?.status === 'error'
				? sendDataLayers(false)
				: null
		}
	}, [mutation.data?.status, mutation.status, sendDataLayers])

	useEffect(() => {
		if (mutation.data?.status) {
			if (mutation.data.status === 'success') {
				form.current?.reset()
			} else {
				console.log('error: ', mutation.data)
			}
		}
	}, [mutation.data])

	const isInView = useIsInView(
		form,
		{
			threshold: 1,
		},
		true,
	)

	useEffect(() => {
		if (isInView) {
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				event: 'form_newsletter_view',
				hit: {
					event_type: 'generic_event_content',
					content_type: 'form',
				},
				_clear: true,
			})
		}
	}, [isInView])

	return (
		<div className={styles.wrapper}>
			<form className={styles.wrapper} onSubmit={onSubmit} ref={form}>
				<div className={styles.main}>
					<div className={styles.form} id={list?.image?.id}>
						<div className={styles.inputWrapper}>
							<Input
								type="email"
								placeholder={translation('MailingListForm.emailPlaceholder')}
								onChange={(event) => setEmail(event.target.value)}
								value={email}
							/>
						</div>
						<div className={styles.buttonWrapper}>
							<Button size="small" type="submit" disabled={mutation.isPending}>
								{translation('MailingListForm.button.label')}
							</Button>
						</div>
					</div>
				</div>
			</form>
			{mutation.data?.status != undefined &&
				(mutation.data.status === 'success' ? (
					<div className={styles.submitNote}>
						{successNote ? <RichTextRenderer source={successNote} /> : translation('newsletter.subscription.success')}
					</div>
				) : mutation.data.errorResponse && mutation.data.errorResponse.code === 'duplicate_parameter' ? (
					<div className={styles.submitNote}>{translation('newsletter.subscription.duplicate_parameter')}</div>
				) : (
					<div className={styles.submitNote}>{translation('newsletter.subscription.error')}</div>
				))}
		</div>
	)
}
