import 'hiding-header/dist/style.css'
import type { FunctionComponent } from 'react'
import type { ArticleTileResult } from '../data/ArticleFragment'
import { FrontButton } from './FrontButton'
import { FrontHeader } from './FrontHeader'
import type { FrontPageProps } from './FrontPage'
import styles from './FrontTvArticle.module.sass'
import type { IconName } from './Icon'
import { LinkChildren } from './LinkChildren'
import { VideoBanner } from './VideoBanner'

type FrontTvArticleProps = {
	article: ArticleTileResult
	buttonLabel?: string
	buttonLink: FrontPageProps['tvPageUrl']
	iconName: IconName
	title: string
}
export const FrontTvArticle: FunctionComponent<FrontTvArticleProps> = ({
	article,
	buttonLabel,
	buttonLink,
	iconName,
	title,
}) => {
	const content = <VideoBanner article={article} isFrontPageLocated />
	return (
		<div className={styles.wrapper}>
			<FrontHeader title={title} iconName={iconName} />

			{article.link?.url ? (
				<LinkChildren link={article.link} type="internal">
					{content}
				</LinkChildren>
			) : (
				content
			)}
			{buttonLink && buttonLabel && <FrontButton buttonLabel={buttonLabel} buttonLink={buttonLink} />}
		</div>
	)
}
