import type { FunctionComponent } from 'react'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
import { useReactToPrint } from 'react-to-print'
import styles from './ArticleShareTrack.module.sass'
import { Icon } from './Icon'
import { useTranslate } from './contexts/TranslationsContextProvider'

type ArticleShareTrackProps = {
	componentRef: React.RefObject<HTMLDivElement>
	title: string | undefined
	articleUrl?: string
}
const BASE_URL =
	process.env.NODE_ENV === 'production' ? process.env.NEXT_PUBLIC_WEB_URL : 'https://magazin.almacareer.com'

export const ArticleShareTrack: FunctionComponent<ArticleShareTrackProps> = ({ articleUrl, title, componentRef }) => {
	const translate = useTranslate()

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	})

	const urlFb = `${BASE_URL}${articleUrl}?utm_source=facebook&utm_medium=share&utm_campaign=Sdileni+clanku+FB`
	const urlLi = `${BASE_URL}${articleUrl}?utm_source=Linkedin&utm_medium=share&utm_campaign=Sdileni+clanku+Li`
	const urlX = `${BASE_URL}${articleUrl}?utm_source=X&utm_medium=share&utm_campaign=Sdileni+clanku+X`

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>{translate('articlePage.shareTrack')}</div>
			<div className={styles.socials}>
				<FacebookShareButton url={urlFb} className={styles.iconButton}>
					<div className={styles.iconBg}>
						<div className={styles.iconWrapper}>
							<Icon name="facebook" />
						</div>
					</div>
				</FacebookShareButton>
				<LinkedinShareButton url={urlLi} className={styles.iconButton}>
					<div className={styles.iconBg}>
						<div className={styles.iconWrapper}>
							<Icon name="linkedin" />
						</div>
					</div>
				</LinkedinShareButton>
				<TwitterShareButton url={urlX} title={title} className={styles.iconButton}>
					<div className={styles.iconBg}>
						<div className={styles.iconWrapper}>
							<Icon name="x" />
						</div>
					</div>
				</TwitterShareButton>
			</div>
			<div className={styles.printWrapper}>
				<button className={styles.iconBg} onClick={handlePrint}>
					<div className={styles.iconWrapper}>
						<Icon name="print" />
					</div>
				</button>
			</div>
		</div>
	)
}
