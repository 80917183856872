const getMonthAndYear = (date: string) => date.split('T')[0].substring(0, 7)

const dateCode = (code: string) => {
	if (code === 'cz') {
		return 'cs'
	} else if (code === 'pl') {
		return 'pl'
	} else {
		return 'en'
	}
}

export const getYearAndMonthFromDate = (date: string, siteCode: string) => {
	const dateCodeBySite = dateCode(siteCode)

	const yearNumber = getMonthAndYear(date).split('-')[0]
	const monthNumber = getMonthAndYear(date).split('-')[1]

	/* On client side for Firefox the "Intl.DateTimeFormat" can't be used. Because the error 
		"RangeError: date value is not finite in DateTimeFormat format()" is thrown.
	*/
	const monthName = Intl.DateTimeFormat(dateCodeBySite, { month: 'long' }).format(new Date(monthNumber))

	return `${monthName} ${yearNumber}`
}

export const getMonthFromDate = (date: string, siteCode: string) => {
	const dateCodeBySite = dateCode(siteCode)
	const monthNumber = getMonthAndYear(date).split('-')[1]

	/* On client side for Firefox the "Intl.DateTimeFormat" can't be used. Because the error 
		"RangeError: date value is not finite in DateTimeFormat format()" is thrown.
	*/
	return Intl.DateTimeFormat(dateCodeBySite, { month: 'long' }).format(new Date(monthNumber))
}

export const getDayFromDate = (date: string) => {
	const dateWithoutTime = date.split('T')[0]
	const day = dateWithoutTime.split('-')[2]

	return day
}
