import 'hiding-header/dist/style.css'
import type { FunctionComponent } from 'react'
import type { ArticleTileResult } from '../data/ArticleFragment'
import { FrontArticleBanner } from './FrontArticleBanner'
import { FrontHeader } from './FrontHeader'
import styles from './FrontMagazineArticles.module.sass'
import { FrontMagazineBanner } from './FrontMagazineBanner'
import type { IconName } from './Icon'

type FrontMagazineArticlesProps = {
	articles: ArticleTileResult[]
	title: string
	iconName: IconName
}
export const FrontMagazineArticles: FunctionComponent<FrontMagazineArticlesProps> = ({ articles, title, iconName }) => {
	const [firstArticle, ...restArticles] = articles

	return (
		<div className={styles.wrapper}>
			<FrontHeader title={title} iconName={iconName} />
			<div className={styles.firstArticleWrapper}>
				<FrontMagazineBanner article={firstArticle} />
			</div>
			<div className={styles.content}>
				{restArticles.map((article) => (
					<FrontArticleBanner article={article} key={article.id} mobileLayout="horizontal" objectFit="cover" />
				))}
			</div>
		</div>
	)
}
